@import "../../styles/colors";
@import "../../styles/functions";

.case-details {
  padding: rem(25px) 0;
  &__caseNumber {
    font-weight: 700;
    font-size: rem(18px);
    line-height: rem(27px);
    color: $grey15;
  }
  &__metadata {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }
  &__pipedList {
    width: rem(291px);
  }
  &__location {
    margin-right: rem(5px);
    img {
      margin-left: rem(23px);
      top: rem(5px);
      position: relative;
      right: rem(4px);
    }
  }
  h4 {
    font-size: rem(12px);
    color: $grey19;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    letter-spacing: rem(1.5px);
    line-height: 20px;
  }
  &__topic-box {
    border-radius: rem(7px);
    border: 1px solid $grey13;
    &.people-picker {
      padding: 8px 54px 8px 8px;
    }
    width: rem(306px);
    height: rem(111px);
    overflow: hidden;
  }
  &__topic-box__scroll-container {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    width: rem(210px);
    padding-right: 62px;
  }
  &__topic-box__scroll-container--topics:empty:after {
    content: "Choose Topics worked on";
    color: $gray1;
    opacity: 0.6;
    font-size: rem(13px);
  }
  &__topic-box__scroll-container--skills:empty:after {
    content: "Choose Skills worked on";
    color: $gray1;
    opacity: 0.6;
    font-size: rem(13px);
  }
  &__topic-box__share {
    background-color: white;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    color: $white;
    font-size: rem(22px);
    text-align: center;
    line-height: 0;
    top: 5px;
    right: 5px;
    border: none;
    cursor: pointer;

    &:disabled {
      background-color: $bodyTextLighter;
      cursor: default;
    }
  }
  &__topic-box__scroll {
    right: rem(9px);
    bottom: rem(11px);
    background-color: $bodyColorMobile;
    width: rem(26px);
    border-radius: rem(4px);

    button {
      display: block;
      background: none;
      padding: 0;
      border: none;
      line-height: normal;
      cursor: pointer;

      &:disabled {
        cursor: default;
        background: none;
        border: none;
      }
    }

    i {
      font-size: rem(22px);
      display: block;
    }
  }

  & > .content {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    hr {
      border: 1px solid $tableActBorderProf;
    }
  }

  &__hours {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $gray7;
    border-radius: 10px;

    p {
      font-size: 20px;
      font-weight: bold;
    }
  }

  p,
  div {
    padding: 2px 0;
    margin: 0;
  }

  .case-type {
    font-size: rem(12px);
    line-height: rem(18px);
  }
}

.ui.grid.case-details {
  > .row {
    padding: 0;
  }
}

.ui.segment.case-details__loadingSegment {
  box-shadow: none;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: none;
  height: 100%;
}

.ui.label.case__tag-readOnly.case-details__tags.cases-tags {
  border: 1px solid $bodyColorMobile;
  background-color: $bodyColorMobile;
  padding: 5px 11px 5px 11px;
}

p.case-details__title.case__case_title.cursor-pointer.fw-bold.mr-1,
.ui.grid > .row > .column .case-details__noDesc {
  font-size: rem(24px);
  font-weight: 700;
  line-height: rem(26px);
}

.ui.grid > .row {
  &.case-details {
    &__metaDataRow {
      padding-top: 0px;
    }
    &__titleRow {
      padding-bottom: 0px;
    }
  }
}
