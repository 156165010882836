@import "../../../styles/colors";
@import "../../../styles/functions";

.profile__completion {
  &--tooltip {
    font-weight: 400;
    width: 315px;
    font-size: rem(14px);
    line-height: rem(24px);
    div {
      margin-left: rem(5px);
    }
    ul.ui.list {
      margin-left: 2.1rem;
      li:before {
        color: $white;
      }
    }
  }
}
