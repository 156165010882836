@import "../../../styles/colors";
@import "../../../styles/functions";

.ui.grid .featured__credentials {
  position: relative;
  padding: rem(5px);

  & > div.row:nth-child(1) {
    padding-bottom: 0.5rem;
    padding-top: 0.1rem;
  }

  &__name {
    font-style: normal;
    font-weight: 700;
    font-size: rem(12px);
    line-height: rem(20px);
    display: flex;
    align-items: center;
    color: $bodyText;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: rem(12px);
    line-height: rem(18px);
    margin-top: 0.5rem;
    color: $bodyText;
    width: 85%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__defaultBorder {
    &::after {
      content: "";
      position: absolute;
      top: rem(-5px);
      left: rem(-5px);
      bottom: rem(-5px);
      right: rem(-5px);
      border: rem(2px) $featuredBlueBorder dashed;
      pointer-events: none;
    }
  }

  &__greenBorder {
    &::after {
      content: "";
      position: absolute;
      top: rem(-5px);
      left: rem(-5px);
      bottom: rem(-5px);
      right: rem(-5px);
      border: rem(2px) $calendarGreen dashed;
      pointer-events: none;
    }
  }

  &__redBorder {
    &::after {
      content: "";
      position: absolute;
      top: rem(-5px);
      left: rem(-5px);
      bottom: rem(-5px);
      right: rem(-5px);
      border: rem(2px) $red dashed;
      pointer-events: none;
    }
  }

  &-label {
    width: 152px;
    background: $featuredBlueLabel;
    border-radius: rem(4px);
    font-weight: 400;
    font-size: rem(10px);
    line-height: rem(15px);
    display: flex;
    align-items: center;
    color: $white;
    text-transform: uppercase;
    padding: rem(2px) rem(16px);
  }
}
