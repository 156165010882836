@import "../../styles/_functions.scss";

.profile {
  &__title {
    font-size: rem(48px);
    line-height: rem(56px);
    font-family: "Henderson BCG Serif";
    padding-bottom: rem(9px);
    width: auto;
    word-break: break-word;
  }

  &__designation {
    font-size: rem(16px);
    font-weight: normal;
    line-height: rem(30px);
    padding-bottom: rem(8px);
    flex-flow: row wrap;

    &--awards {
      font-size: rem(16px);
      font-weight: bold;
      line-height: rem(30px);
    }
    &--title {
      padding-right: rem(16px);
    }
  }
}

@media (max-width: 767px) {
  .profile {
    &__title {
      font-size: rem(40px);
      line-height: rem(48px);
    }
    &__designation {
      padding-bottom: 0px;
      line-height: rem(30px);
    }
    &__awards {
      line-height: rem(30px);
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .profile {
    &__title {
      font-size: rem(25px);
    }
  }
}
