@import "../../styles/colors";
@import "../../styles/functions";

.finder {
  &__list__box {
    margin-right: rem(10px);
  }

  &__column__wrapper {
    height: rem(324px);
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: auto;
    margin-top: 1.5rem;
    color: $bodyText;
    max-width: rem(750px);
    min-width: 520px;
  }

  &__column {
    width: rem(200px);
    display: inline-block;
    height: 100%;
    vertical-align: top;
    font-size: rem(12px);
    overflow: auto;
    margin-right: rem(2px);
    line-height: rem(20px);
    &:empty {
      display: none;
    }
    &__heading {
      font-family: "Henderson BCG Sans";
      font-size: rem(12px);
      line-height: rem(14px);
      color: $gray1;
      padding-bottom: rem(5px);
      padding-left: rem(18px);
      letter-spacing: 0.8px;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: rem(0px);
    line-height: rem(20px);
    padding: rem(5px) rem(10px);
    padding-left: rem(18px);
    white-space: normal;
    min-height: rem(38px);
    width: 100%;

    margin-bottom: 2px;

    &:not(.finder__disabled, .finder__item--selected):hover {
      background-color: $grey13;
      + div {
        background-color: $grey13;
      }
    }

    div {
      color: $bodyText;
    }
    i {
      font-size: rem(12px);
      margin: 0;
      margin-top: rem(5px);
      color: $bodyTextLighter;
    }

    &--selected {
      background-color: $primaryGreen;
      & > div {
        color: $bodyColorMobile;
      }
      + div {
        border: 1px solid $bodyColorMobile;
        color: $bodyColorMobile;
        background-color: $primaryGreen;
        &:hover {
          background-color: $primaryGreen;
        }
      }
      &:hover {
        background-color: $primaryGreen;
        + div {
          border: 1px solid $bodyColorMobile;
          color: $bodyColorMobile;
          background-color: $primaryGreen;
          &:hover {
            background-color: $primaryGreen;
          }
        }
      }
    }
  }

  &__disabled {
    & > div {
      color: #333;
    }
    background: $grey12;
    &:hover {
      background-color: $grey13;
      & + div,
      i {
        background-color: $grey13;
      }
    }
  }

  &__noResult {
    background: $black;
    border-radius: 0 0 7px 7px;
    padding: 7px 1rem;
    color: $white;
    margin: 0 -1rem;
    position: absolute;
    width: 100%;
    bottom: 0px;
  }
}
