@import "../../../styles/colors";
@import "../../../styles/functions";

.tags {
  display: flex;
  align-items: center;
  .ui.label {
    background: $bodyColorMobile;
    border: 1px solid $grey18;
    border-radius: rem(16px);
    font-style: normal;
    font-weight: 400;
    font-size: rem(12px);
    line-height: rem(18px);
    color: $black;
    min-width: rem(58px);
    max-width: rem(160px);
    margin-right: rem(11px);
    padding: rem(5px) rem(11px);
  }
  .ui.label.tag-limited {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
