@import "../../../styles/functions";
@import "../../../styles/colors";

.profile__image-container {
  position: relative;
  width: rem(140px);
  height: rem(140px);

  &::after {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    width: 100%;
    height: 100%;
    border: 2px dashed $iconOrange;
    border-radius: 60px;
    animation: rotateCircle 15s linear 5;
    box-sizing: border-box;
  }
}

.profile__image {
  border-radius: rem(60px);
  object-fit: cover;
  z-index: 1;

  &--small {
    height: rem(75px);
    width: rem(75px);
  }

  &--medium {
    height: rem(130px);
    width: rem(130px);
  }

  &--popup.ui.popup {
    padding: 0;
    border-radius: rem(4px);
    border: 1px solid $comboBoxBorder;
    background: $white;
    box-shadow: 0px 2px 3px 0px rgba(112, 110, 107, 0.5);
  }

  &--options.ui.relaxed.list {
    color: $gray--blackish;
    font-size: rem(12px);
    font-style: normal;
    font-weight: 400;
    line-height: rem(20px);
    & .item {
      padding: rem(10px);
      .title {
        &:hover {
          cursor: pointer;
          color: $primaryGreen;
        }
      }
      .newIcon {
        margin-top: -15px;
      }
    }
    .subtitle {
      font-weight: 300;
      font-size: rem(10px);
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.ui.modal > .content.profile__image__hires--modal-container {
  flex-flow: column;
}

@media (max-width: 767px) {
  .profile__image {
    &--medium {
      height: rem(80px);
      width: rem(80px);
    }
  }
}

.ui.relaxed.list:not(.horizontal) > .item:not(:first-child) {
  padding-top: 10px;
}

@keyframes rotateCircle {
  100% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(360deg);
  }
}
