@import "../../styles/colors";
@import "../../styles/functions";

.experience {
  a {
    text-decoration: underline;
  }
  &__item {
    display: flex;
    word-break: break-word;

    &__image {
      width: rem(36px);
      height: rem(36px);
      margin-right: 0.9rem;
      background: $primaryDarkerGreen;
      border-radius: rem(5px);
      text-align: center;
      display: inline-flex;
      flex: 0 0 rem(36px);
      vertical-align: middle;

      & img {
        width: rem(25px);
        height: rem(23px);
      }
    }

    &__text {
      flex: 1;
      line-height: 1.5;
      color: $bodyText;

      &--bold {
        font-weight: bold;
        font-size: 1.143rem;
        line-height: 1.857rem;
      }

      &--gray {
        color: $bodyText;
        font-weight: 300;
        font-size: 1.143rem;
        line-height: 1.857rem;
      }

      &--small {
        font-size: 0.857em;
        line-height: 1.429rem;
      }

      &--small-gray {
        font-size: 0.857em;
        color: $bodyTextLight;
        line-height: 1.429rem;
        word-spacing: 1px;
      }

      span {
        padding: 0 0.3rem;
      }
    }
  }

  &__content {
    color: $bodyText;
    flex: 1;

    &__small {
      font-size: 0.857rem;
      line-height: 1.429rem;

      & a {
        text-decoration: underline;
      }
    }

    &__icon {
      color: $gray1;
    }
  }

  &__wrap {
    height: 40px;

    & div {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding-right: 10px;
    }

    & p {
      padding-right: 10px;
    }
  }
}

//start: pre-bcg experience modal window
.ui {
  &.modal {
    &.experience__modal {
      border-radius: rem(5px);
      background-color: $white;
      box-shadow: 0 rem(2px) rem(12px) 0 rgba(0, 0, 0, 0.2);
      max-width: rem(1100px);
    }

    > .header {
      &.experience__modal {
        &__title {
          padding-top: rem(25px);
          padding-bottom: rem(10px);
          color: $bodyText;
          font-family: "Henderson BCG Serif";
          font-size: rem(32px);
          font-weight: bold;
          border-bottom: none;
        }
      }
    }

    > .content {
      &.experience__modal {
        &__content {
          padding: 0;
          max-height: rem(400px);
          overflow-y: auto;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .experience {
    &__content {
      &__small {
        display: none;
      }
    }
  }
  .ui {
    &.modal {
      &.experience__modal {
        padding-top: 0;
      }

      > .header {
        &.experience__modal {
          &__title {
            border-bottom: 1px solid rgba(34, 36, 38, 0.15);
          }
        }
      }

      > .content {
        &.experience__modal {
          &__content {
            max-height: 100%;
          }
        }
      }
    }
  }
}
