@import "../../../styles/functions";

.ui.grid > .tablet.column.pagination-wrapper {
  &__meta {
    left: 0;
    top: rem(13px);
    position: absolute;
  }

  @media (max-width: 991px) {
    &__pagination-box {
      text-align: center;
    }
    &__meta {
      position: static;
      text-align: center;
      padding-bottom: 0;
    }
  }
}
