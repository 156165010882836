@import "../../styles/_functions.scss";

.profile-cases {
  &:empty {
    display: none;
  }

  .ui.label.case__tag-readOnly.cases-tags {
    max-width: rem(146px);
  }

  :global(.file-row) {
    max-width: rem(400px);
  }

  .ui.grid > .row > .column.custom-grid__topics {
    padding-left: 0;
  }
}

.ui.grid > .row.case__tags-mobile {
  padding-top: 0;
}

@media (max-width: 991px) {
  .profile-cases {
    .ui.grid > .row > .column.custom-grid__topics {
      padding-left: 1rem;
    }
  }
}
