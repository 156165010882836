@import "../../../styles/_functions.scss";
@import "../../../styles/colors";

.languages-summary {
  &__mobile-view {
    display: none;
  }
  &__desktop-view {
    display: block;
  }
  @media (max-width: 1023px) {
    &__mobile-view {
      display: block;
    }
    &__desktop-view {
      display: none;
    }
  }
}

.ui.table {
  thead th {
    &.languages-summary {
      &__table-header {
        height: rem(20px);
        width: rem(144px);
        color: $gray1;
        font-family: "Henderson BCG Sans";
        font-size: rem(12px);
        font-weight: bold;
        letter-spacing: rem(0.8px);
        line-height: rem(20px);
        text-transform: uppercase;
        padding: rem(7px) rem(14px);
      }
    }
  }
  tr td {
    &.languages-summary {
      &__table-cell {
        font-family: "Henderson BCG Sans";
        font-size: rem(14px);
        color: $bodyText;
        padding: rem(8px) rem(14px);
      }
    }
  }
  &.languages-summary__table--main {
    border: 1px solid $bodyColorMobile;
  }
}

h5.languages-summary__title {
  font-size: rem(16px);
  line-height: rem(18px);
}
