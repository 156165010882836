@import "../../../styles/colors";
@import "../../../styles/functions";

.content > .recorder {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  & > * {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &__rec-time {
    color: $bodyText;
    font-size: rem(14px);
    letter-spacing: 0;
    line-height: rem(40px);
    margin-top: 0;
  }
}
