@import "../../../styles/functions";
@import "../../../styles/colors";

.copy-text {
  min-width: 100px;
  & strong {
    margin-right: rem(3px);
  }
  & img {
    opacity: 0;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}
