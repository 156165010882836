@import "../../../styles/colors";
@import "../../../styles/functions";

.ui.table {
  &.compact.very tr > td {
    color: black;
    font-weight: normal;
    .ui.checkbox {
      margin-right: 1.5rem;
    }
  }
  thead th {
    background-color: rgba(216, 216, 216, 0.26);
  }
  tr > td {
    div.vignettes__lastUpdatedBy {
      margin-left: 2.7rem;
      font-weight: 300;
      font-size: 0.75rem;
      color: darkgrey;
    }
  }
}

.ui.table .vignettes {
  &__button-container {
    float: right;
  }
  &__shareIcon {
    padding-left: rem(6px);
  }

  &__fileName {
    align-items: flex-start;
    margin-bottom: rem(3px);
    span {
      color: $bodyText;
      margin-left: rem(10px);
    }
  }
}

@media (max-width: 1023px) {
  .vignettes {
    &__button-container {
      float: left;
      margin-top: 0.75rem;
    }
  }
}

.compact.button.vignettes__button {
  border: 2px solid $primaryGreen;
  border-radius: rem(5px);
  color: $white;
  background-color: $primaryGreen;
  text-transform: uppercase;
  padding: rem(5px) rem(15px);
  &:disabled,
  &--disabledBtn {
    border: 2px solid $bodyTextLighter;
    background-color: $white;
    color: $bodyText;
    opacity: 0.45;
  }
}
