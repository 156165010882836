@import "../../../styles/functions";
@import "../../../styles/colors";

.icon__circle {
  border: rem(2px) solid $white;
  border-radius: 50%;

  background-repeat: no-repeat;
  background-position: 50% 50%;

  @media (max-width: 1023px) {
    background-position: 50% 50%;
  }

  &--medium {
    height: 3.55rem;
    width: 3.55rem;
    padding: 0.8rem;
  }
  &--small {
    height: 2.3rem;
    padding: 0.8rem;
    width: 2.3rem;
  }
}
