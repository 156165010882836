@import "../../styles/colors";
@import "../../styles/functions";

.vertical__stepper {
  position: relative;
  padding-left: rem(16px);
  list-style: none;

  &-item {
    position: relative;
    counter-increment: list;

    &:not(:last-child) {
      padding-bottom: rem(11px);
    }

    & h4 {
      font-style: normal;
      font-weight: 700;
      font-size: rem(12px);
      line-height: rem(20px);
      color: $grey19;
      margin-bottom: 0;
    }

    & p {
      font-style: normal;
      font-weight: 400;
      font-size: rem(12px);
      line-height: rem(20px);
      color: $bodyTextLight;
    }

    &:not(:last-child)::before {
      display: inline-block;
      content: "";
      position: absolute;
      top: rem(5px);
      left: rem(-12px);
      height: 100%;
      border-left: rem(1px) solid $grey13;
    }

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: rem(5px);
      left: rem(-15px);
      width: rem(8px);
      height: rem(8px);
      border-radius: 50%;
      background-color: $grey18;
    }
  }
}
