@import "../../styles/colors";
@import "../../styles/functions";

.edit-about {
  &__block {
    position: relative;
    padding: 26px 0 20px;
  }

  &__block + &__block {
    &:after {
      position: absolute;
      width: 100%;
      height: 1px;
      content: "";
      top: 0;
      left: 0;
      background-color: rgba(34, 36, 38, 0.15);
    }
  }
  &__profileList {
    &__icon {
      color: $gray1;
    }
    p {
      color: $bodyTextLight;
      font-size: rem(12px);
      font-weight: normal;
      margin-bottom: 5px;
    }

    p + p {
      color: $bodyText;
      font-weight: bold;
      line-height: rem(20px);
    }
  }
}

.ui.segment.edit-about__loadingSegment {
  box-shadow: none;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: none;
  height: 100%;
}

.ui.dropdown .menu > .disabled.item {
  opacity: 1;
  & > span {
    opacity: 0.45;
  }
}

.tootltipMessage {
  display: inline-block;
  margin-left: 10px;
}
