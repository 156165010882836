@import "../../../styles/colors";
@import "../../../styles/functions";

.info__area {
  .ui.bottom.center.large.inverted.popup.transition.visible {
    min-width: rem(380px);
    min-height: rem(120px);
    border-radius: 4px;
    padding-bottom: rem(20px);
  }
  &--text {
    font-weight: 400;
    font-size: rem(14px);
    line-height: rem(24px);
    div {
      margin-left: rem(5px);
    }
    ul.ui.list {
      margin-left: 2.1rem;
      li:before {
        color: $white;
      }
    }
  }
}
