@import "../../styles/colors";
@import "../../styles/functions";

.education {
  &__item {
    display: flex;
  }
  &__icon {
    background-color: $primaryDarkGreen;
    margin-right: rem(16px);
    min-width: rem(30px);
    min-height: rem(30px);
    width: rem(30px);
    height: rem(30px);
    border-radius: 100%;
    margin-top: (9px);
  }
  &__content {
    color: $bodyText;
    flex: 1;

    &__small {
      font-size: 0.857rem;
      line-height: 1.429rem;

      & a {
        text-decoration: underline;
      }
    }

    &__icon {
      color: $gray1;
    }
  }
  &__heading {
    font-size: rem(16px);
    line-height: rem(26px);
  }
  &__text {
    font-weight: 300;
    font-size: rem(16px);
    line-height: rem(26px);

    &--sm {
      color: $bodyTextLight;
      font-size: rem(12px);
      line-height: rem(20px);
    }
  }
}

.ui.modal {
  &.education__modal {
    border-radius: rem(5px);
    background-color: $white;
    box-shadow: 0 rem(2px) rem(12px) 0 rgba(0, 0, 0, 0.2);
  }
  > .header.education__modal {
    &__title {
      padding-top: rem(25px);
      padding-bottom: rem(10px);
      color: $bodyText;
      font-family: "Henderson BCG Serif";
      font-size: rem(32px);
      line-height: rem(58px);
      border-bottom: none;
    }
  }
  > .content.education__modal {
    &__content {
      padding: 0;
      max-height: rem(400px);
      overflow-y: auto;
    }
  }
}

@media (max-width: 1023px) {
  .ui.modal {
    &.education_modal {
      padding-top: 0;
    }
    > .content.education__modal {
      &__content {
        max-height: 100%;
      }
    }
    > .header.education__modal {
      &__title {
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
      }
    }
  }

  .education {
    &__content {
      &__small {
        display: none;
      }
    }
  }
}
