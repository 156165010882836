@import "../../../../styles/colors";
@import "../../../../styles/functions";

.ui.button.buttonWithText {
  padding: rem(0px);
  width: rem(106px);
  background: rgba(255, 255, 255, 0.01);
  height: rem(24px);
  text-transform: uppercase;
  font-size: rem(12px);
  border-radius: unset;
  font-weight: 400;
  line-height: rem(18px);
  letter-spacing: unset;
  color: $grey16;
  &:hover {
    text-decoration: underline;
    color: $primaryDarkGreen;
    font-weight: 700;
    border-radius: unset;
    background: $grey20;
  }
}
