@import "../../../styles/functions";
@import "../../../styles/colors";

.ui.button.edit__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  color: $calendarGreen;
  outline: 1px solid $calendarGreen;
  border-radius: 0px;
  padding: rem(7px) rem(13px);
  font-size: rem(12px);
  font-style: normal;
  font-weight: 700;
  line-height: rem(18px);
  &:hover {
    color: $darkestGreen;
    outline: 2px solid $darkestGreen;
  }
  > img {
    margin-top: -2px;
    margin-right: 5px;
  }
  .find_icon {
    margin-right: 5px;
  }
}
