@import "../../../styles/functions";
@import "../../../styles/colors";

.piped-list {
  & span {
    margin-right: 5px;
    font-size: rem(12px);
    line-height: rem(20px);
    font-weight: 400;
    color: $black;
    &:after {
      content: "|";
      padding-left: rem(7px);
    }
    &:last-child:after {
      display: none;
    }
  }
}
