@import "../../../styles/_functions.scss";
.notification__custom__modal {
  &.ui.modal {
    width: 620px;
    padding: 30px 60px;
    .warningpopupcontent {
      font-size: rem(16px);
      .message {
        padding-left: 6px;
      }
    }
  }
}
