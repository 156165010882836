@import "../../../styles/colors";
@import "../../../styles/functions";

.topic-picker {
  &__chip {
    padding: 2px 7px 3px;
    background-color: $bodyColorMobile;
    top: 4px;
    left: 5px;
    border-radius: 4px;
    &__name {
      font-size: rem(12px);
      color: $bodyText;
      line-height: 2;
      margin-right: 10px;
    }
  }
  &__placeHolder {
    width: 100px;
    float: left;
    margin-right: 20px;
    margin-top: 9px;
  }
  &__iconContainer {
    background-color: $black;
    border-radius: 5px;
    margin: 1px;
    height: calc(100% - 2px);
  }
  &__singleItemPadding {
    padding: 3px 0 5px;
  }
}

.icon.topic-picker__chip__delete {
  color: $white;
  background: $bodyTextLighter;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  line-height: 22px;
  opacity: 1;
  margin-top: 2px;
}

.ui.icon.input > .icon.topic-picker__inputIconSlot {
  opacity: 1;
  padding: 2px;
}
