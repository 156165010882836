@import "../../../styles/colors";
@import "../../../styles/functions";

.ui {
  &.modal {
    &.credentials__modal {
      min-width: 65%;
      border-radius: rem(5px);
      background-color: $white;
      box-shadow: 0 rem(2px) rem(12px) 0 rgba(0, 0, 0, 0.2);
      transition: width 0.8s;
    }
    > .header {
      padding: 0;
      padding-left: rem(35px);
      padding-right: rem(35px);
      border-bottom: none;
      .title {
        padding: rem(25px) 0;
        color: $bodyText;
        font-family: "Henderson BCG Serif";
        font-style: normal;
        font-weight: 700;
        font-size: rem(24px);
        line-height: rem(27px);
        border-bottom: rem(2px) solid $bodyColorMobile;
      }
    }
    .caption {
      font-family: "Henderson BCG Sans";
      font-style: normal;
      font-weight: 700;
      font-size: rem(20px);
      line-height: rem(25px);
      color: $bodyText;
    }
    .sub_caption {
      font-family: "Henderson BCG Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $grey16;

      > i.icon {
        color: $iconOrange;
      }
    }
  }
  &.segment {
    &.credentials__modal {
      &__loadingSegment {
        box-shadow: none;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: none;
        height: 100%;
      }
    }
  }
}

.credentials__modal {
  &__content {
    padding: 0;
    max-height: rem(385px);
  }
}

span {
  &.credentials__modal__form-warning {
    color: $bodyText;
    font-size: 1rem;
  }
}

@media (max-width: 1023px) {
  .ui {
    &.modal {
      > .header {
        &.credentials__modal {
          &__title {
            border-bottom: 1px solid rgba(34, 36, 38, 0.15);
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
  .credentials__modal {
    &__content {
      max-height: 100%;
    }
    &__md-none {
      display: none;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ui.modal.credentials__modal {
    box-shadow: none;
  }
  .credentials__modal {
    &__md-none {
      display: flex;
    }
  }
}
