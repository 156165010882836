@import "../../../styles/colors";
@import "../../../styles/functions";

tr.role__tree-row {
  & > td:nth-child(2) {
    border-left: rem(1px) solid $gray20 !important;
    padding-left: rem(15px);
    position: relative;
    &:before {
      position: absolute;
      content: "";
      border: rem(1px) solid $gray20;
      width: rem(8px);
      left: 0;
      top: 50%;
    }
  }
}
tr.role__tree-row td.role__tree-second-cell:after,
tr.role__tree-row td.role__tree-last-cell:after {
  content: "";
  background: $white;
  margin-left: -1px;
  height: 50%;
  border: 0;
  vertical-align: top;
  width: 1px;
  position: absolute;
  left: 0;
  top: 0;
}
tr.role__tree-row td.role__tree-last-cell.gray-cell:after {
  background-color: $tr-background;
}
tr.role__tree-row td.role__tree-last-cell:after {
  top: auto;
  bottom: 0;
}
td.role__tree-first-cell {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    border: 1px solid $gray20;
    width: rem(25px);
    right: 0;
    top: 50%;
  }
}
