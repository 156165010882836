@import "../../styles/functions";
@import "../../styles/colors";

.banner {
  background: linear-gradient(125deg, rgba(50, 124, 88, 0) 20%, rgba(37, 87, 69, 90) 40%);
  background-color: $bannerGreen;
  padding-top: 3.36rem;
  padding-bottom: 4.5rem;
  color: $white;

  &__container.ui.container {
    display: flex;
    flex-flow: row wrap;
    position: static;
    .banner {
      &__heading {
        min-height: rem(100px);
        min-width: 50%;
        margin-right: rem(90px);
      }

      &__content {
        margin-top: rem(7px);
        flex: 1;
        padding-left: rem(57px);
        &--top {
          border-bottom: rem(2px) solid rgba(255, 255, 255, 0.6);
          align-items: center;
        }
        &--bottom {
          flex-flow: row wrap;
          font-weight: 300;
          padding-top: 0.7rem;
        }
      }
      &__assistant--info {
        flex: 1;
      }
      &__profile--details {
        flex: 2;
        display: flex;
        flex-flow: column wrap;
        height: 6.5rem;

        & > div {
          flex-basis: 50%;
          max-width: 50%;
          min-height: 3.1rem;
          display: flex;
          align-items: flex-start;
          flex-flow: row nowrap;
          &.banner__birth-date {
            .exclamation-icon {
              margin-top: 2px;
              height: 20px;
              width: 20px;
            }
          }
        }
      }
      &__icons {
        display: flex;
        height: rem(50px);
        align-items: center;
        & > :nth-child(2) {
          margin-left: rem(15px);
        }

        &--contact {
          display: flex;
          div,
          span,
          a {
            margin-right: rem(15px);
            &:last-child {
              margin-right: 0px;
            }
          }

          span {
            background-position: rem(12px) rem(-10px);
            background-repeat: no-repeat;
            background-size: rem(22px) rem(65px);
            filter: brightness(0) invert(1);

            &:hover {
              background-color: $white;
              filter: none;
            }
          }
        }

        &--settings {
          padding-left: rem(15px);

          span {
            background-size: rem(27px) ren(68px);
            background-repeat: no-repeat;
            background-position: center center;
            filter: brightness(0) invert(1);

            &:hover {
              background-color: $white;
              filter: none;
            }
          }
        }
      }

      &__image {
        flex-flow: column wrap;
        align-items: center;
      }
      &__profile-status {
        margin-top: rem(16px);
      }
      &--web-visible {
        display: block;
      }
      &--web-hidden {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ui.container.banner {
    &__container {
      width: 93%;
      .banner {
        &__content {
          padding-left: rem(27px);
        }
        &__profile--details {
          & > div {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .banner {
    padding: rem(48px) rem(12px) rem(50px);
    flex-flow: column wrap;
    &__container.ui.container {
      display: block;
      .banner {
        &__heading {
          min-height: rem(1px);
        }
        &--mobile-visible {
          display: block;
        }
        &--mobile-hidden {
          display: none;
        }
        &__content {
          padding-left: 0;
          margin-right: 0;
          flex: none;
          &--top {
            padding-bottom: rem(24px);
            align-items: flex-start;
            flex-flow: column wrap;
            padding-top: 1rem;
          }
        }
        &__profile--details {
          position: absolute;
          top: 5.5rem;
          right: 0.8rem;
          flex-flow: column;
          text-align: right;

          & > div {
            max-width: 100%;
            min-height: 2rem;
            padding-top: 0;
            padding-right: 0.5rem;
            display: block;

            > span:first-child {
              float: right;
              width: 1.5rem;
              padding-left: 0.5rem;
            }
          }
        }

        img.ml-h {
          margin-top: 5px;
        }
        &__icons {
          margin-top: 2rem;
        }
        &__image {
          align-items: flex-start;
        }
        &__assistant--info {
          flex: none;

          &:empty {
            display: none;
          }
          &__profile-status {
            margin-top: 0rem;
            position: relative;
            top: 0.5rem;
          }

          &--has-loa {
            width: 55%;
            overflow: hidden;
            word-break: break-word;

            span {
              display: block;
            }
          }
          &__content--bottom {
            justify-content: space-between;
          }
          &--has-anon {
            margin-top: 2.5rem;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .banner {
    &--has-anon {
      margin-top: 2rem;
    }
  }
}
