@import "../../styles/functions";
@import "../../styles/colors";

.alumni {
  flex-direction: column;

  &__text {
    line-height: rem(24px);
    padding-top: rem(4px);
  }
}

@media (max-width: 1023px) {
  .alumni {
    div {
      min-width: 4rem;
    }
  }
}
