@import "../../../styles/functions";
@import "../../../styles/colors";

.powered__by__linkedin {
  font-family: "Henderson BCG Sans";
  display: flex;
  & > p {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: rem(14px);
    line-height: rem(21px);
    color: $linkedIn-text-blue;
  }
  & > p::after {
    position: absolute;
    content: "";
    background: url("../../../assets/icons/linkedIn.svg") no-repeat;
    height: rem(16px);
    width: rem(70px);
    background-size: contain;
    top: rem(2px);
    left: rem(75px);
  }
  & > div > img {
    margin-top: rem(-24px);
    margin-left: rem(75px);
  }
}
