@import "../../styles/colors";
@import "../../styles/functions";

.ui.vertical.navigation {
  color: $bodyTextLight;
  &__menu {
    border: none;
    padding: rem(16px) 0;
    box-shadow: 2px 5px 5px $editorBorderDark;
    @media (max-width: 767px) {
      min-width: 100%;
      display: block;
      position: relative;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    }
  }
}

.ui.vertical .item.navigation {
  &__menu__item {
    width: rem(90px);
    height: rem(90px);
    padding: 0;

    font-family: "Henderson BCG Sans";
    font-size: rem(12px);
    line-height: rem(20px);
    border-radius: 0;
    position: static;
    background-color: $white;

    &:nth-child(1) {
      span {
        background-position: rem(69px) rem(27px);
        width: rem(20px);
        height: rem(17px);
      }
    }

    &:nth-child(2) {
      span {
        background-position: rem(68px) rem(32px);
        width: rem(19px);
        height: rem(22px);
      }
    }

    &:nth-child(3) {
      span {
        background-position: rem(69px) rem(26px);
        width: rem(20px);
        height: rem(16px);
      }
    }
    &:nth-child(4) {
      span {
        width: rem(20px);
        height: rem(22px);
      }
    }

    a {
      border-left: 3px solid transparent;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: $bodyTextLight;
      height: 100%;
      width: 100%;
      text-align: center;

      span {
        display: inline-block;
        text-align: center;
        margin: 0 auto 8px;
      }
    }
    @media (max-width: 767px) {
      text-align: left;
      width: 100%;
      height: rem(48px);
      flex-direction: row;
      align-items: center;

      font-size: rem(20px);
      line-height: rem(32px);

      a {
        color: $bodyTextLighter;
        flex-direction: row;
        align-items: center;
        padding: 0 rem(20px);
        justify-content: start;

        span {
          vertical-align: middle;
          padding-right: rem(15px);
          margin: 0 rem(15px) 0 0px;
          text-align: left;
        }
      }
    }
  }
}

.ui.vertical.menu .item.navigation__menu__item > .navigation__menu__item {
  &--active {
    border-left: 3px solid $navIconActive;
    color: $navIconActive;
    font-weight: bold;
    background: none;
  }
}

.scrolled {
  position: fixed;
  top: 57px;
  @media (max-width: 767px) {
    position: relative;
    top: 0;
  }
}
