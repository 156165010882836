@import "../../styles/functions";
@import "../../styles/colors";

.ui.modal.walkme_container {
  width: rem(510px);
  padding: rem(20px) 0 rem(22px) 0;
  border-radius: rem(30px);
  color: $bodyText;

  & .header {
    padding-left: rem(25px);
    display: flex;
    font-size: rem(20px);
    font-style: normal;
    font-weight: 700;
    line-height: rem(25px);
  }

  & .description {
    padding: rem(5px) rem(28px) 0 rem(25px);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: rem(24px);
  }

  .cross_icon {
    margin-left: rem(20px);
    cursor: pointer;
    margin-right: rem(-20px);
    margin-bottom: rem(20px);
  }

  .content {
    padding-bottom: 0;
    p {
      font-size: rem(12px);
      font-style: normal;
      font-weight: 400;
      line-height: rem(20px);

      > strong {
        font-size: rem(12px);
      }
    }

    strong {
      font-size: rem(14px);
      font-style: normal;
      font-weight: 700;
      line-height: rem(26px);
    }
    ul {
      padding-inline-start: rem(30px);
      list-style-type: disc;
      margin-top: 0;
      margin-bottom: rem(18px);
    }
    li {
      font-size: rem(12px);
      font-weight: 400;
      line-height: rem(20px);
    }
  }
  a {
    color: $calendarGreen;
  }
  .actions {
    border-top: none;
    display: flex;
    flex-direction: column;
    padding-left: rem(10px);
    padding-top: 0;

    .green_button {
      background: $calendarGreen;
      color: $white;
      width: rem(290px);
      padding-left: 0;
      padding-right: 0;
      padding-top: rem(5px);
      padding-bottom: rem(5px);
      border-radius: 0;
      font-size: rem(12px);
      font-style: normal;
      font-weight: 400;
      line-height: rem(18px);
      text-transform: uppercase;
      margin-bottom: rem(15px);
      &:hover {
        background: $primaryDarkGreen;
        border-radius: unset;
      }
      .like_icon {
        margin-bottom: rem(-2px);
        margin-right: rem(5px);
      }
    }
    .white_button {
      background-color: $white;
      color: $calendarGreen;
      width: rem(135px);
      text-align: left;
      padding-left: rem(10px);
      font-size: rem(12px);
      font-style: normal;
      font-weight: 400;
      line-height: rem(18px);
      text-transform: uppercase;
      margin-left: 10px;
      &:hover {
        text-decoration: underline;
        color: $primaryDarkGreen;
        font-weight: 700;
        border-radius: unset;
        background: $grey20;
      }
    }
  }
}
