@import "../../../styles/colors";
@import "../../../styles/functions";

.ui.primary.button.recorder-btn {
  height: rem(40px);
  min-width: rem(173px);
}

.recorder-btn {
  &__done {
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(40px);
    width: rem(122px);
    border-radius: rem(5px);
    background-color: rgba(228, 239, 57, 0.3);
    & > p {
      color: $black;
      font-size: rem(12px);
      font-weight: bold;
      letter-spacing: rem(0.8px);
      line-height: 20px;
      text-align: center;
    }
  }
}
