@import "../../styles/colors";
@import "../../styles/functions";

.editWorkDayButton {
  border: 1px solid $white;
  padding-left: rem(25px);
  align-items: center;
  height: rem(40px);
  color: $white;
  min-width: rem(205px);
  &:hover {
    color: $white;
  }
  &__editWorkDayPopOverContent {
    .ui.bottom.left.large.popup.transition.visible {
      min-width: 20rem;
      border-radius: 0;
      cursor: auto;
      > div {
        padding: rem(7px);
        > button {
          background: none;
          color: $calendarGreen;
          border: 1px solid $calendarGreen;
          border-radius: 0;
          font-size: rem(10px);
          margin-bottom: rem(7px);
          letter-spacing: normal;
          padding: rem(6px);
        }
        > div {
          font-size: rem(12px);
          font-weight: 400;
          &:first-child {
            line-height: rem(18px);
            margin-bottom: rem(8px);
          }
          &:nth-child(3),
          &:nth-child(4) {
            align-items: flex-start;
            margin-bottom: rem(10px);
            margin-top: rem(10px);
            > span {
              margin-left: rem(5px);
              font-size: rem(10px);
              line-height: rem(15px);
            }
          }
          &:nth-child(6) {
            align-items: center;
            > span {
              margin-right: rem(20px);
              line-height: rem(24px);
            }
          }
        }
      }
    }
  }
}
