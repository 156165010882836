@import "../../../styles/functions";
@import "../../../styles/colors";

.ui.progress.progress__bar {
  width: 100%;
  & .bar {
    height: rem(4px);
    border-radius: rem(5px);
    background-color: $progressIncomplete;
  }
}

.ui.progress.progress__bar.bar__completed {
  & .bar {
    background-color: $calendarGreen;
  }
}

.percent__label {
  display: flex;
  align-items: center;
  font-family: "Henderson BCG Sans";
  font-style: normal;
  font-weight: 400;
  font-size: rem(14px);
  line-height: rem(24px);
  color: $grey19;
  padding-bottom: rem(10px);
  & > div > span {
    padding-right: rem(8px);
    font-weight: 700;
    font-size: rem(22px);
    line-height: rem(30px);
  }
}
