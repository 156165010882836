@import "../../../../styles/colors";
@import "../../../../styles/functions";

.awardPopup {
  min-height: rem(650px);
  max-height: fit-content;
  width: 73%;
  &__divider {
    background-color: $bodyColorMobile;
    margin-left: rem(15px);
  }
  &__invalidTextWarning {
    margin-top: rem(5px);
    color: $red;
    > span {
      margin-left: rem(5px);
    }
  }
  &__keyToPurposeDesc {
    text-align: center;
    margin-top: rem(10px);
  }
  &__hyperLink {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }
  &__awardNameHeader {
    font-weight: 700;
    font-size: rem(24px);
    font-family: "Henderson BCG Serif";
    font-style: normal;
  }
  h4 {
    font-family: "Henderson BCG Sans";
    font-style: normal;
    font-weight: 700;
    font-size: rem(14px);
    text-transform: uppercase;
    margin-bottom: rem(10px);
  }
  a {
    color: $black;
    &:hover {
      text-decoration: underline;
    }
  }
  &__backButton {
    border-bottom: 2px solid $bodyColorMobile;
    padding-bottom: rem(25px);
    margin-bottom: rem(10px);
    margin-left: rem(15px);
    > span {
      font-weight: 400;
      font-size: 1rem;
      line-height: rem(21px);
      color: $grey15;
      font-family: "Henderson BCG Sans";
      > img {
        margin-right: rem(10px);
      }
    }
  }

  &__awardName {
    font-weight: 700;
    font-size: rem(32px);
    line-height: 117%;
    font-family: "Henderson BCG Serif";
  }
}

.ui.modal.awardPopup {
  > .content {
    min-height: rem(580px);
    max-height: fit-content;
    padding-left: rem(50px);
    padding-right: 3.5rem;
    padding-top: rem(40px);
    & > div > .ui.grid {
      width: 75%;
    }
    h4.awardPopup__mandatoryField {
      &:after {
        color: red;
        content: "*";
      }
    }
  }
  > .actions {
    border-top: 2px solid $bodyColorMobile;
    margin-left: rem(16px);
    margin-right: 3.5rem;
    > .button {
      border: 1.5px solid $calendarGreen;
      font-size: 1rem;
      font-weight: 400;
      background: white;
      text-transform: uppercase;
      line-height: rem(21px);
      color: $calendarGreen;
      height: rem(40px);
      min-width: 6.5rem;
      padding: 0.51429rem 0.71429rem;
      margin-right: rem(-15px);
      &:hover {
        border: 2px solid $primaryDarkestGreen;
        color: $primaryDarkestGreen;
      }
      &.primary {
        background: $calendarGreen;
        color: white;
        margin-left: rem(30px);
        &:hover {
          background-color: $primaryDarkestGreen;
        }
      }
    }
  }
}
