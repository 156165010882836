@import "../../styles/colors";
@import "../../styles/functions";

.anonymized {
  background-color: $bodyText;
  line-height: 22px;
  top: 0;
  position: relative;
  top: -46px;
  padding: 10px 0;
  margin-bottom: -25px;

  i {
    font-size: 1.125rem;
  }
}

.ui.container.anonymized__container {
  flex-flow: row wrap;
  display: flex;
}

@media (max-width: 1023px) {
  .anonymized {
    margin-left: -10px;
    margin-right: -10px;

    .ui.container.anonymized__container {
      display: block;
    }
  }
}
