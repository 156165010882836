@import "../../styles/colors";
@import "../../styles/functions";

.ui.grid.profile__completion {
  & p {
    font-weight: 400;
    font-size: rem(12px);
    line-height: rem(18px);
    color: $grey19;
  }
}

.ui.checkbox.profile__completion--checkbox {
  & label {
    font-weight: 700;
    font-size: rem(14px);
    line-height: rem(21px);
    color: $grey15;
    &::before {
      top: rem(2px);
      border: rem(1px) solid $calendarGreen;
      border-radius: 0;
    }
  }
  & label:hover::before {
    border: rem(2px) solid $primaryDarkGreen;
  }
  & label::after {
    top: rem(2px);
    background-color: $calendarGreen;
    font-weight: 200;
  }
  & label:hover::after {
    background-color: $primaryDarkGreen;
  }
}
