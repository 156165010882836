@import "../../styles/colors";
@import "../../styles/functions";

.achievement {
  &__content {
    flex-direction: row wrap;
  }

  &__image {
    margin-top: rem(5px);
  }
  &__text {
    margin-left: 0.9rem;
  }

  &__title {
    a,
    p {
      font-size: rem(16px);
      line-height: rem(26px);
      color: $bodyText;
      word-break: break-word;
    }
    a:hover {
      border-bottom: rem(1px) solid $black;
      padding-bottom: rem(2px);
    }
  }
  &__date {
    color: $bodyTextLight;
    font-size: rem(12px);
    line-height: rem(20px);
    font-weight: 400;
  }
  &__heading {
    font-size: rem(20px);
    line-height: rem(32px);
    margin-bottom: rem(16px);
    margin-top: rem(5px);
    color: $bodyText;
  }
}
