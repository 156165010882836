@import "../../../styles/_functions.scss";
@import "../../../styles/_colors.scss";

.ui.card > .content p,
p {
  &.org-member {
    color: $bodyText;
    font-family: "Henderson BCG Sans";
    &__image {
      height: rem(55px);
      width: rem(55px);
      border-radius: rem(50px);
      display: block;
      margin-top: rem(2px);
      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }
    }
    &-border {
      border: rem(1px) solid $bodyTextLighter;
      padding: rem(8px);
      border-radius: rem(8px);
    }

    &__location {
      margin: 0;
      line-height: rem(20px);
      font-size: rem(12px);
      color: $bodyTextLight;
    }
    &__title {
      line-height: rem(20px);
      margin: 0;
      font-size: rem(12px);
      max-width: rem(220px);
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__name {
      margin: 0;
      color: $bodyText;
      line-height: rem(20px);
      font-size: rem(12px);
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.d-flex.align-items-start.org-member {
  &-border {
    border: rem(1px) solid $bodyTextLighter;
    padding: rem(8px);
    border-radius: rem(8px);
  }
}

img.org-member__image {
  height: rem(55px);
  width: rem(55px);
  border-radius: rem(50px);
  display: block;
  margin-top: rem(2px);
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}
