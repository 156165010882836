@import "../../../../styles/functions";
@import "../../../../styles/colors";

.expand__button {
  align-items: center;
  background: transparent;
  border: none;
  color: $primaryGreen;
  font-family: "Henderson BCG Sans";
  font-weight: bold;
  font-size: rem(14px);
  line-height: rem(24px);
  margin: rem(16px) 0 0 0;
  outline: none;

  &__icon {
    border: 1.5px solid $primaryGreen;
    margin-right: rem(5px);
    width: rem(15px);
    height: rem(15px);

    &:before,
    &:after {
      background-color: $primaryGreen;
      content: "";
      display: block;
      position: absolute;
      width: 75%;
      height: 1.5px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &.expanded {
      &:after {
        content: none;
      }
    }
  }

  &__text {
    color: $primaryGreen;
  }
}
