@import "../../../styles/colors";

.icon__circle {
  border: 2px solid $white;
  border-radius: 50%;

  &--medium {
    height: 3.55rem;
    width: 3.55rem;
    padding: 0.8rem;
  }
}
