@import "../../styles/colors";
@import "../../styles/functions";

.ui {
  .grid {
    &.edit-profile {
      &__sectionBlock {
        margin: 0 rem(66px);
        padding: rem(16px) 0;
      }
    }
    .ui.header:first-child.edit-profile__sectionBlock__subHeading {
      font-size: rem(16px);
    }
  }
  .table > thead > tr.edit-profile__proBioTable__head {
    font-size: rem(12px);
    line-height: 20px;
    color: $bodyTextLight;

    & td {
      padding: 0.78571429em 0.78571429em 0.78571429em 0;
    }
    & span {
      color: $red;
    }
  }
  .label.edit-profile {
    &__labelActive {
      font-size: rem(10px);
      background-color: $white;
      color: $bodyText;
      border: solid 2px $bodyText;
      cursor: pointer;
      & i {
        margin: 0 0 0 0.5em;
      }
    }
    &__labelDisabled {
      font-size: rem(10px);
      background-color: $white;
      opacity: 0.2;
      color: $bodyText;
      border: solid 2px $bodyText;
      & i {
        margin: 0 0 0 0.5em;
      }
    }
  }
  .table > tbody.edit-profile__proBioTable {
    & tr {
      & td {
        border-top: none;
        font-size: rem(12px);
        line-height: 20px;
        color: $bodyText;
        padding: 0.78571429em 0.78571429em 0.78571429em 0;

        &:last-child {
          padding-left: 1em;

          & i {
            color: $bodyTextLighter;
            font-size: 1.2rem;

            &:hover {
              color: $primaryGreen;
            }
          }

          .visible {
            .icon {
              color: $primaryGreen;
            }
          }
        }
      }
    }
  }
  .list {
    &.edit-profile__tipsList {
      font-size: rem(12px);
      margin-top: 1.75rem;
      margin-left: 0.5rem;

      li {
        line-height: rem(18px);
        &:before {
          margin-left: -0.5rem;
        }
      }
    }
  }
  .header {
    &.edit-profile {
      &__tipsHeading {
        color: $gray1;
        text-transform: uppercase;
      }

      &__lang {
        font-weight: normal;
        margin: 0 0 rem(15px);
        color: $bodyText;

        span:last-child {
          span {
            display: none;
          }
        }
      }
    }
  }
  &.segment {
    &.edit-profile__loadingSegment {
      box-shadow: none;
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: none;
      height: 100%;
    }
  }
  .table td {
    &.edit-profile {
      &__mandatoryField {
        & > div {
          &.ui.fluid.dropdown {
            border: solid 1px $warningYellow;
          }
        }
      }
      &__optDropdown {
        vertical-align: middle;

        & > div > div {
          right: 2.5em;
          left: auto;
          background-color: $bodyText;

          & > div.item {
            color: $white;
            font-size: rem(12px);
            line-height: 20px;
            width: 110px;

            &:hover {
              color: $primaryGreen;
            }
          }
        }
      }
    }
  }
  &.fluid.dropdown {
    &.edit-profile__langDropdown {
      font-size: rem(14px);
      color: $bodyText;
      border: 1px solid rgba(34, 36, 38, 0.15);

      & ::-webkit-scrollbar {
        width: 0.4em;
      }
      & ::-webkit-scrollbar-thumb {
        background-color: $bodyText;
      }

      & > div:not(:first-child) {
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-top: 1px solid rgba(34, 36, 38, 0.15);
        margin-top: 1px;

        & > div:hover {
          background-color: $bodyText;
          color: $white;
        }
      }
    }
  }
}

.edit-profile {
  font-size: rem(12px);

  p {
    font-size: rem(12px);
    line-height: 20px;
  }
  //   &__top-bar {
  //     background-color: $bodyColorMobile;
  //     padding-left: rem(66px) !important;
  //     padding-right: rem(66px) !important;
  //     margin-bottom: 30px !important;
  //   }
  &__divider {
    border: 1px solid $tableActBorderProf;
  }
  &__validationMsg {
    color: $bodyText;
    display: flex;
    float: left;
    align-items: center;
    * {
      margin: 0px 10px 0px 10px;
    }
  }
  &__profileList {
    &__icon {
      color: $gray1;
    }
    &__block {
      min-height: 40px;
      margin-bottom: 1.5rem;
      & img {
        cursor: pointer;
      }
      & span {
        width: rem(70px);
        float: left;
        margin-bottom: rem(3px);

        & i {
          vertical-align: middle;
        }

        &::after {
          content: ":";
          float: right;
        }

        &:empty {
          &::after {
            display: none;
          }
        }
      }
      & p:nth-child(2) {
        & i {
          vertical-align: middle;
        }
      }
    }

    p {
      color: $bodyTextLight;
      font-size: rem(12px);
      font-weight: normal;
      margin-bottom: 5px;
    }

    p + p {
      color: $bodyText;
      font-weight: bold;
      line-height: rem(20px);
    }
  }
}
