@import "../../../styles/functions";

.ui.list {
  &.phone-list {
    flex-direction: column;
    padding: 0 0.5rem 0 0;
    @media (max-width: 767px) {
      padding: 0;
    }
    span {
      width: rem(50px);
      font-weight: normal;
      margin-right: rem(7px);
      float: left;

      &::after {
        content: ":";
        float: right;
        font-weight: bold;
      }

      &:empty {
        &::after {
          display: none;
        }
      }

      &.copy {
        display: inline;
        width: 4rem;

        margin-right: 0;
        &::after {
          display: none;
        }
      }
      &.phone-list {
        &__copy-number {
          height: rem(15px);
          float: right;
          margin-top: rem(-2px);
          margin-bottom: rem(-3px);
          margin-right: 0;
          width: auto;

          > img {
            &:hover {
              cursor: pointer;
            }
          }

          &::after {
            display: none;
          }
        }
      }
    }

    p {
      line-height: 1;
      float: left;
    }

    b {
      display: block;
      margin-bottom: rem(6px);

      &:last-child {
        margin-bottom: rem(4px);
      }
    }

    &__work-phones {
      display: inline;
    }

    &__work-phone-item {
      margin-left: rem(72px);
      padding-top: 0;
      margin-bottom: rem(6px);

      &:first-child {
        margin-top: -1rem;
      }

      &:last-child {
        margin-bottom: rem(4px);
      }

      > span {
        width: auto;
        font-weight: bold;
        margin-right: rem(7px);
        float: left;

        &::after {
          display: none;
        }
      }
    }
  }
  > .phone-list {
    &__list {
      padding: 0 0 rem(9px);

      &:last-child {
        padding-bottom: rem(2px);
      }
    }
  }
}

.phone-list {
  &__copy-suffix {
    float: right;
  }
  &__copy-separator {
    border-left: rem(1px) solid #e0e0e0;
    align-self: center;
    margin-left: rem(7px);
    margin-right: rem(7px);
    display: inline;
  }
}
