@import "../../../styles/functions";

.linkedin__fixed {
  position: fixed;
  bottom: rem(300px);
  right: 0;
  & > img.li_small-logo {
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.3s ease;
  }
  & > img.li_full-logo {
    position: absolute;
    top: rem(-3px);
    right: rem(-100px);
    transition: all 0.3s ease;
    cursor: pointer;
  }
  &:hover {
    & > img.li_small-logo {
      opacity: 0;
    }
    & > img.li_full-logo {
      right: 0;
    }
  }
}
