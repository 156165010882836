@import "../../../styles/colors";
@import "../../../styles/_functions";

.assistant-to-info {
  & > span {
    line-height: 1.5rem;
    font-weight: bold;
  }
  p {
    margin: 0;
  }
  a {
    display: inline-block;
    color: $white;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.ui.assistant-to-info__extra-exec-popup {
  border-radius: 0;
  color: $bodyTextAlt;

  p {
    padding: 0.6rem 2.5rem 0 0.5rem;

    &:first-of-type {
      padding-top: 1.1rem;
    }
    &:last-of-type {
      padding-bottom: 0.6rem;
    }
  }

  a {
    color: $bodyTextAlt;
    font-family: "Henderson BCG Sans";
    font-weight: 400;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: $primaryDarkerGreen;
    }
  }
}

@media (max-width: 1023px) {
  .assistant-to-info {
    display: none;
  }
}
