@import "../../../styles/colors";
@import "../../../styles/_functions";

.banner-clock {
  &__details {
    a {
      color: $white;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
