// Variables, mixins, and utility classes

//  Font Face
@font-face {
  font-family: "Henderson BCG Sans";
  src: url("../assets/fonts/HendersonBCGSans-Regular.woff2") format("woff2"),
    url("../assets/fonts/HendersonBCGSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Henderson BCG Sans";
  src: url("../assets/fonts/HendersonBCGSans-Thin.woff2") format("woff2"),
    url("../assets/fonts/HendersonBCGSans-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Henderson BCG Serif";
  src: url("../assets/fonts/HendersonBCGSerif-Bold.woff2") format("woff2"),
    url("../assets/fonts/HendersonBCGSerif-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Henderson BCG Serif";
  src: url("../assets/fonts/HendersonBCGSerif-Regular.woff2") format("woff2"),
    url("../assets/fonts/HendersonBCGSerif-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Henderson BCG Sans";
  src: url("../assets/fonts/HendersonBCGSans-Bold.woff2") format("woff2"),
    url("../assets/fonts/HendersonBCGSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Henderson BCG Sans";
  src: url("../assets/fonts/HendersonBCGSans-Light.woff2") format("woff2"),
    url("../assets/fonts/HendersonBCGSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  font-size: 100%;
  background-color: $bodyColor;
  font-family: "Henderson BCG Sans", Georgia, "Times New Roman", Times, serif;
  font-weight: normal;
  color: $bodyText;
  padding-top: 57px;
  padding-bottom: 40px;

  @media (max-width: 767px) {
    background-color: $bodyColorMobile;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
.ui.header {
  font-family: "Henderson BCG Sans", Georgia, "Times New Roman", Times, serif;
}

// Mixin - Margin and Padding classes

$spacer: 1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  h: (
    x: (
      $spacer-x * 0.5
    ),
    y: (
      $spacer-y * 0.5
    )
  ),
  hq: (
    x: (
      $spacer-x * 0.75
    ),
    y: (
      $spacer-y * 0.75
    )
  ),
  1: (
    x: $spacer-x,
    y: $spacer-y
  ),
  2: (
    x: (
      $spacer-x * 1.5
    ),
    y: (
      $spacer-y * 1.5
    )
  ),
  3: (
    x: (
      $spacer-x * 3
    ),
    y: (
      $spacer-y * 3
    )
  ),
  4: (
    x: (
      $spacer-x * 4
    ),
    y: (
      $spacer-y * 4
    )
  )
);

@mixin spacing() {
  @each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $lengths in $spacers {
      $length-x: map-get($lengths, x);
      $length-y: map-get($lengths, y);

      .#{$abbrev}-#{$size} {
        #{$prop}: $length-y $length-x !important;
      }

      // a = All sides
      .#{$abbrev}t-#{$size} {
        #{$prop}-top: $length-y !important;
      }

      .#{$abbrev}r-#{$size} {
        #{$prop}-right: $length-x !important;
      }

      .#{$abbrev}b-#{$size} {
        #{$prop}-bottom: $length-y !important;
      }

      .#{$abbrev}l-#{$size} {
        #{$prop}-left: $length-x !important;
      }
    }
  }
}

@include spacing;

// Mixins - Transform
@mixin transform($property) {
  transform: $property;
}

// Mixins - Border radius
@mixin border-radius($property) {
  border-radius: $property;
}

//Mixins - Shadow
@mixin card-shadow {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
}

// Mixins - Font size, line-height or both

// Adds font-size in rem & px
// Value passed should be the expected px size
// Default value 12 - can be customized to fit project
@mixin font-size($sizeValue: 12) {
  font-size: $sizeValue + px; //fallback for old browsers
  font-size: (0.0625 * $sizeValue) + rem;
}

// helpers

a,
.link {
  color: $primaryGreen;

  &:hover {
    color: $primaryGreen;
  }
}

.link-textcolor {
  color: $gray1;
  text-decoration: underline;

  &:hover {
    color: $gray1;
    text-decoration: underline;
  }
}

a.muted,
.text-muted {
  color: $bodyTextLighter;
}

* {
  outline: none;
}

.mx-auto {
  margin: 0 auto !important;
}

.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.ml-h {
  margin-left: 0.5rem;
}

.mr-5 {
  margin-right: 5rem;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.ml-2_5 {
  margin-left: 2.5rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-h {
  margin-bottom: 0.5rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-6 {
  margin-top: 6rem;
}

.p-0 {
  padding: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pr-h {
  padding-right: 0.5rem !important;
}

.pt-h {
  padding-top: 0.2rem !important;
}

.pb-h {
  padding-bottom: 0.2rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-16 {
  padding: 16px !important;
}

.p-5 {
  padding: 5px !important;
}

.ml-half {
  margin-left: 8px;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex !important;
}

.flex-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end;
}

.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

.position-static {
  position: static !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.order-first {
  order: -1 !important;
}

.order-last {
  order: 99 !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.d-none {
  display: none !important;
}

.v-none.visible.transition {
  visibility: hidden !important;
}

.fs-12 {
  font-size: 0.857rem !important;
}

.top-0 {
  top: 0 !important;
}

.word__break--normal {
  word-break: normal;
}

.word__break--breakAll {
  word-break: break-all;
}

.overflow__wrap--bw {
  overflow-wrap: break-word;
}

.add-icon {
  background: $bodyText;
  width: 32px;
  height: 32px;
  display: inline-block;
  text-align: center;
  padding-top: 5px;
  border-radius: 5px;
}

.borderTop-none {
  border-top: none !important;
}

.clear-icon {
  background: $bodyText;
  width: 22px;
  height: 22px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  border-radius: 5px;

  & > :first-child {
    transform: rotate(45deg);
  }
}

.overflow-hidden {
  overflow: hidden;
}

hr {
  border-top: 1px solid $gray3;
}

.btn-disabled {
  cursor: not-allowed;
  opacity: 0.4;
  pointer-events: none;
}

.fw-normal {
  font-weight: normal !important;
}

.fw-bold {
  font-weight: bold !important;
}

textarea {
  font-family: "Henderson BCG Sans", Georgia, "Times New Roman", Times, serif;
  line-height: 20px !important;

  &:disabled {
    background-color: $bodyColor !important;
    border-color: rgba(183, 183, 183, 0.49) !important;
    resize: none !important;

    &:active,
    &:focus {
      border-color: rgba(183, 183, 183, 0.49) !important;
    }
  }
}

.disabled-input {
  background-color: $bodyColor !important;
  border-color: rgba(183, 183, 183, 0.49) !important;
}

.overflow-auto {
  overflow: auto;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.text-align-center {
  text-align: center !important;
}

.verticle-align-middle {
  vertical-align: middle !important;
}

.border-right {
  border-right: 1px solid $bodyTextLight;
}

.mt-auto {
  margin-top: auto !important;
}

.position-fixed {
  position: fixed;
}

@media (max-width: 767px) {
  .mobile-order-1 {
    order: 1;
  }

  .mobile-order-2 {
    order: 2;
  }

  .mobile-order-3 {
    order: 3;
  }

  .mobile-order-4 {
    order: 4;
  }

  .mobile-order-5 {
    order: 5;
  }

  .mobile-order-6 {
    order: 6;
  }

  .mobile-order-7 {
    order: 7;
  }

  .m-d-none {
    display: none !important;
  }
}

i.bcg-darker-green.icon {
  color: $primaryDarkerGreen !important;
}
