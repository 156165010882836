@import "../../../styles/colors";
@import "../../../styles/functions";

.vertical__ellipsis {
  padding: 0;
  margin: 0;
  & li {
    background: $bodyTextLight;
    height: rem(2px);
    width: rem(2px);
    line-height: 0;
    list-style: none outside none;
    margin-top: rem(5px);
    vertical-align: top;
    border-radius: 50%;
  }
}
