@import "../../styles/colors";
@import "../../styles/functions";

.error {
  &__container {
    background-color: $white;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__title {
    font-size: rem(48px);
    line-height: rem(53px);
    text-transform: uppercase;
    margin: rem(25px) auto;
  }
  &__message {
    font-size: rem(20px);
    line-height: rem(30px);
    text-align: center;
    text-transform: uppercase;
    white-space: pre-wrap;
    margin: rem(16px) i;
  }
  // &__button {
  //   font-size: rem(18px) !important;
  //   line-height: rem(20px);
  //   letter-spacing: rem(1.2px);
  //   border-radius: rem(5px) !important;
  //   text-align: center;
  //   color: $white;
  //   margin: rem(24px) 0 0 !important;
  // }
}
