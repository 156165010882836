@import "./functions";
@import "./colors";
// Contains all the CSS that handles the layout, e.g. container, grid systems and custom layout.

// Page Wrapper

.page-wrapper {
  max-width: rem(1440px);
  margin: 0 auto;
}

//Page Container
//Overriding semantic ui styles.
.ui {
  &.container {
    position: relative;
    transition: width 0.3s;
    @media (min-width: 768px) {
      width: calc(100% - 240px);
    }

    @media (min-width: 1440px) {
      width: rem(1140px);
    }
  }
}

//Masonry Grid - Profile pages
.masonry-grid {
  display: flex;
  margin-left: rem(-30px); /* gutter size offset */
  width: auto;

  &__column {
    padding-left: rem(30px); /* gutter size */
    background-clip: padding-box;
    width: 100% !important ;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media (max-width: 1259px) {
      .profile-2col-grid {
        .row {
          > .column {
            flex-basis: 100%;
            margin-bottom: 1.714rem;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      width: 100% !important;
      display: block;
      max-width: rem(390px);

      &:first-child {
        width: 100% !important;
        max-width: rem(780px);
      }
    }

    @media (max-width: 768px) and (min-width: 321px) {
      > div {
        margin-bottom: rem(20px) !important;
      }

      &:first-child {
        width: 100% !important;
      }
    }
  }
}

.masonry-gridIpad {
  margin-right: -7rem;
  display: flex;
  margin-left: rem(-30px); /* gutter size offset */
  width: auto;
  margin-bottom: 1.714rem;
  &__column {
    padding-left: rem(30px); /* gutter size */
    background-clip: padding-box;
    width: 100% !important ;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &:first-child {
      width: 100% !important;
      max-width: rem(896px);
    }
  }
}

// Pages

.full__page {
  &--top {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
    margin-bottom: rem(-30px) !important;

    @media (max-width: 767px) {
      top: 0.5rem;
    }

    @media (max-width: 1023px) {
      margin-bottom: 1rem !important;
    }
  }

  &--normal-header {
    padding: 1.2rem 0 0.714rem !important;
  }

  &--loading {
    min-height: rem(300px) !important;
    font-size: 1rem;
    align-items: center;

    .content {
      align-items: center;
      display: flex;

      .loader {
        color: $bodyTextLighter;
      }
    }
  }

  &--header {
    flex-grow: 0 !important;
    padding: 0 rem(66px) 0 rem(66px) !important;
    margin: 0 !important;
    border-bottom: rem(2px) solid $bodyColorMobile !important;
  }

  &--content {
    padding: 0 rem(66px) rem(20px) rem(66px) !important;
    margin: 0 !important;
  }

  @media (max-width: 767px) {
    &--content,
    &--header {
      padding-left: 1.143rem !important;
      padding-right: 1.143rem !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .mdScreenFluidCard {
    width: 118% !important;
    max-width: 850px !important;
  }
  .full__page {
    &--header {
      flex-grow: 0 !important;
      margin: 0 !important;
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
      border-bottom: rem(2px) solid $bodyColorMobile !important;
    }
    &--content {
      margin: 0 !important;
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
  }
}

//Left Navigation

.left-nav-wrapper {
  &.ui.grid {
    > .row {
      padding: 0 !important;
    }
  }
}

.left-nav {
  margin-bottom: 1.42rem !important;
  margin-top: -1.214rem;
  @media (min-width: 768px) {
    position: absolute !important;
    left: -7.428rem;
    // top: -2.428rem;
    width: 6.428rem;
    margin-top: 0;
  }
}

.main-wrapper {
  @media (min-width: 768px) {
    top: -2.357rem;
  }
}
