@import "../../../styles/colors";
@import "../../../styles/functions";

.ui.label.pill {
  padding: rem(10px) rem(16px);
  border-radius: rem(15px);
  max-width: 220px;
  color: $white;
  min-width: rem(128px);
}
