@import "../../../styles/functions";
@import "../../../styles/colors";

.ui.modal {
  &.linkedin__cancel__popup {
    max-width: rem(627px);
    color: $grey19;
    padding: rem(16px);
    & > .header {
      padding: 0;
      font-style: normal;
      font-weight: 700;
      display: flex;
      justify-content: center;
      line-height: rem(58px);
      font-size: rem(16px);
      border: none;
    }
    & > .content {
      padding: rem(10px) rem(20px);
      font-style: normal;
      font-weight: 700;
      font-size: rem(14px);
      line-height: rem(24px);
      span {
        font-weight: normal;
      }
    }
    .ui.form textarea,
    .ui.form textarea:focus {
      background: $white;
      border: rem(1px) solid $bodyTextLighter;
      border-radius: rem(4px);
      font-family: "Henderson BCG Sans";
      font-style: normal;
      font-weight: 400;
      font-size: rem(14px);
      color: $grey19;
      min-height: rem(90px);
    }
    & .actions {
      padding: rem(20px) rem(32px);
      display: flex;
      justify-content: center;
      & > .ui.button.primary {
        width: rem(130px);
      }
    }
    .warning-message {
      padding: 0 rem(20px) rem(10px);
      display: flex;
      justify-content: center;
      margin: rem(10px) rem(20px) rem(5px);
      align-items: center;
      text-align: center;
      border-bottom: 1px dashed;
    }
  }
}
