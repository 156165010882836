@import "../../../../styles/colors";
@import "../../../../styles/functions";

.finder {
  &__searchBox {
    input[type="text"] {
      height: 40px;
      width: 100%;
      min-width: 520px;
      border-radius: 7px;
      font-size: rem(14px);
    }

    i {
      font-size: 16px;
      color: $black;
    }
  }

  &__searchResiltBox {
    width: 100%;
    max-width: 628px;
    z-index: 1;
    background: $white;
    padding: 1rem 0 3.2rem 1rem;
    position: absolute;
    box-shadow: 0 0 3px $bodyText;
    border-radius: 7px;
    top: 51px;

    > div {
      max-height: 230px;
      overflow: auto;
    }
  }

  &__noResult {
    background: $black;
    border-radius: 0 0 7px 7px;
    padding: 7px 1rem;
    color: $white;
    margin: 0 -1rem;
    position: absolute;
    width: 100%;
    bottom: 0px;
  }

  &__disabled {
    cursor: default;
    h5,
    p {
      > span {
        color: $bodyTextLighter;
      }
    }
  }
}
