@import "../../../styles/functions";
@import "../../../styles/colors";

.ui.modal.li__remove__access {
  max-width: rem(509px);
  & .warn__header {
    font-family: "Henderson BCG Sans";
    font-style: normal;
    font-weight: 700;
    font-size: rem(14px);
    line-height: rem(20px);
    color: $grey19;
  }

  & .contents {
    padding-top: rem(30px);
    & > p {
      margin-bottom: rem(5px);
      font-family: "Henderson BCG Sans";
      font-style: normal;
      font-weight: 700;
      font-size: rem(12px);
      line-height: rem(24px);
      color: $grey19;
    }

    .ui.form textarea,
    .ui.form textarea:focus {
      background: $white;
      border: rem(1px) solid $bodyTextLighter;
      border-radius: rem(4px);
      font-family: "Henderson BCG Sans";
      font-style: normal;
      font-weight: 400;
      font-size: rem(14px);
      color: $grey19;
      min-height: rem(90px);
    }
  }
  & .actions {
    & > .ui.button.primary {
      width: rem(150px);
    }
  }
}

.center__item {
  text-align: center;
}
