@import "../../../styles/colors";
.confirmation__custom__modal {
  &.ui.modal {
    width: 500px;
    padding: 20px;
    .ui.segment.loading__segment {
      position: relative;
      .ui.dimmer,
      .ui.loader {
        background: white;
        &::after {
          border-color: $primaryGreen transparent transparent;
        }
      }
    }
  }
}
