@import "../../../styles/colors";
@import "../../../styles/functions";

.c-expertise {
  position: relative;
  z-index: 1000;

  &__heading {
    box-sizing: border-box;
    margin-bottom: rem(16px);
  }

  &__title {
    color: $bodyText !important;
    display: inline-block !important;
    font-size: rem(16px) !important;
    line-height: rem(17px) !important;
    margin: 0 !important;
    padding-right: rem(14px) !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--fixedWidth {
      width: rem(130px);
      white-space: normal;
      overflow: initial;
      text-overflow: initial;
      font-size: rem(12px) !important;
      line-height: rem(20px) !important;
      color: $gray1 !important;
    }
  }

  &__percentage {
    color: $primaryGreen !important;
    display: inline-block !important;
    margin: 0 !important;
    padding-left: rem(14px) !important;
    border-left: 1px solid $grey9 !important;
    font-size: rem(24px) !important;
    line-height: rem(27px) !important;
    font-weight: normal !important;
    width: rem(75px);
  }
  &__checkbox {
    margin-left: 30px !important;
    font-size: rem(12px) !important;
    opacity: 0.7;
  }

  &--small {
    width: 85%;
  }
}

@media (max-width: 767px) {
  .c-expertise {
    &__percentage {
      width: 4rem;
    }
  }
}
