@import "../../../styles/colors";
@import "../../../styles/functions";

.ui.grid > .viewEditProfileCompletion {
  &__borderTop {
    margin-top: rem(10px);
    margin-bottom: rem(-10px);
    border-top: 1px solid $bodyColorMobile;
    padding-top: rem(20px);
    padding-right: 100%;
  }
}

.ui.grid.viewEditProfileCompletion {
  padding-top: rem(10px);
}
