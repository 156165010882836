@import "../../../styles/functions";
@import "../../../styles/colors";

.linkedin__popup {
  max-width: rem(627px);
  padding: rem(10px);
  &-main-content {
    background-color: $grey17;
  }
  &-text {
    font-style: normal;
    font-size: rem(14px);
    line-height: rem(24px);
    color: $bodyText;
    & > ul {
      max-width: 50%;
      margin-bottom: 0;
    }
  }
  &-actions {
    width: 100%;
    .ui.button {
      min-width: rem(112px);
      width: rem(112px);

      &:hover {
        min-width: rem(112px);
        width: rem(112px);
      }
    }
  }
  &-actions-2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
    .ui.button {
      min-width: rem(120px);
      width: rem(150px);
      &:hover {
        min-width: rem(120px);
        width: rem(150px);
      }
    }
  }
  &-content {
    position: relative;
    padding: rem(20px) rem(20px) 0 rem(20px);
    width: 100%;
    background-color: $grey17;
    margin-top: rem(85px);
    border: 0px solid #979797;
    & > .avatar-linkedin {
      position: absolute;
      top: rem(-60px);
      left: rem(180px);
    }
    & > .avatar-kn {
      position: absolute;
      border-radius: 50%;
      top: rem(-60px);
      right: rem(180px);
    }
  }
  &-guide {
    margin: 0;
    padding: rem(20px);
    background-color: $bodyColorMobile;
    font-size: rem(10px);
    line-height: rem(16px);
    & > ul {
      padding: 0 rem(18px);
    }
  }

  .ui.slider.checkbox label {
    &:before {
      height: 0.5rem;
      width: 2.5rem;
    }
    &:after {
      top: 0.1rem;
      background-color: $white;
      height: rem(16px);
      width: rem(16px);
    }
  }
  .ui.slider.checkbox.checked label {
    &:before {
      height: 0.5rem;
      width: 2.5rem;
      background-color: $cardBorder-green !important; // added to override the semantic ui css which also has !important added
      color: $cardBorder-green !important; // added to override the semantic ui css which also has !important added
    }
  }
  .ui.slider.checkbox input:checked ~ .box,
  .ui.slider.checkbox input:checked ~ label {
    color: $cardBorder-green !important;
    background-color: $cardBorder-green !important; // added to override the semantic ui css which also has !important added
  }
  .ui.slider.checkbox input:focus:checked ~ .box:before,
  .ui.slider.checkbox input:focus:checked ~ label:before {
    background-color: $cardBorder-green !important; // added to override the semantic ui css which also has !important added
  }
  .ui.slider.checkbox input:checked ~ .box:after,
  .ui.slider.checkbox input:checked ~ label:after {
    left: 1.5rem;
    border: rem(1px) solid $calendarGreen;
  }
}

.policy__content {
  & > a.not__now {
    font-style: normal;
    font-weight: 400;
    font-size: rem(12px);
    line-height: rem(18px);

    display: flex;
    align-items: center;
    text-align: center;

    color: $calendarGreen;
    border-right: 1px solid $grey18;
    padding: 0 rem(20px);
  }
  & > a.privacy__policy {
    font-style: normal;
    font-weight: 400;
    font-size: rem(12px);
    line-height: 18px;
    color: $grey15;
    padding: 0 rem(20px);
    &:hover {
      text-transform: uppercase;
      background: $grey17;
      animation: transform 0.2s;
      text-decoration: underline;
    }
  }
}
.highlight_days {
  display: inline-block;
  border-radius: 50%;
  background: #fce1dc;
  font-weight: 700;
  padding: 8px;
  color: #660f09;
}
.skips_left {
  font-style: italic;
  color: $grey15;
  font-family: "Henderson BCG Sans", sans-serif;
  font-size: 10px;
}
.remind_me_later {
  margin-left: 40px;
  text-align: center;
  .ui.button {
    width: 11rem;
    font-size: 12px;
    color: $primaryDarkerGreen;
    background: transparent;
    padding: 0;
    margin: 0;
  }
}
