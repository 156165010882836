@import "../../styles/functions";
@import "../../styles/colors";

.ui.fluid > .content.about {
  padding-bottom: 0;
  &__block + &__block {
    position: relative;
    padding-top: rem(16px);
    &:after {
      position: absolute;
      width: rem(310px);
      height: 1px;
      content: "";
      top: 0;
      left: 0;
      background-color: $bodyTextLighter;
    }
  }

  p {
    margin-bottom: rem(16px);
    font-weight: bold;
    line-height: rem(24px);

    span {
      font-weight: normal;
      display: block;
      line-height: rem(24px);
      font-size: rem(16px);
    }
  }
  h5,
  h4 {
    font-weight: bold;
    line-height: rem(24px);
    margin: 0;
  }

  h4 {
    font-size: rem(16px);
    font-weight: 400;
  }
}
