@import "../../styles/_functions.scss";
@import "../../styles/_colors.scss";

.org-structure {
  color: $bodyText;
  font-size: rem(12px);

  &__header {
    line-height: rem(30px);
    font-size: rem(14px);
    background-color: $backgroundGrey;
    padding: 0 0 0 1.8rem;
    font-weight: bold;
  }
  &__members {
    max-height: rem(400px);
    overflow-y: scroll;
  }
}

.ui {
  &.modal > .header.org-structure__heading {
    color: $bodyText;
    font-family: "Henderson BCG Serif";
    font-size: rem(32px);
    font-weight: bold;
    line-height: rem(58px);
    padding: 0.8rem 0.8rem;
    padding-left: 1.5rem;
  }
  &.grid.org-structure__grid {
    margin-top: -1.5rem;
    margin-right: -1.5rem;
  }
}
