.icon-text {
  &__image {
    height: 16px;
  }
}

@media (max-width: 767px) {
  .label__text {
    display: none;
  }
}

.banner__container.ui.container .banner__profile--details > .icon-text--with-subtext {
  flex-flow: row wrap;
}
