@import "../../../styles/colors";
@import "../../../styles/functions";

.credentialsPopup {
  min-height: rem(760px);
  max-height: fit-content;
  width: 73%;
  &__divider {
    background-color: $bodyColorMobile;
    margin-left: rem(15px);
  }
  &__invalidTextWarning {
    margin-top: rem(5px);
    color: $red;
    > span {
      margin-left: rem(5px);
    }
  }
  &__duplicateMessage {
    font-family: "Henderson BCG Sans";
    font-weight: 300;
    font-size: rem(14px);
    color: $black;
    margin-top: rem(8px);
    margin-bottom: rem(-8px);
    > span {
      margin-left: rem(5px);
    }
  }
  &__header {
    font-weight: 700;
    font-size: rem(24px);
    font-family: "Henderson BCG Serif";
    font-style: normal;
  }
  &__hyperLink {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }
  &__skillTag.ui.label {
    background: $bodyColorMobile;
    border: 1px solid $grey18;
    border-radius: rem(16px);
    font-style: normal;
    font-weight: 400;
    font-size: rem(12px);
    line-height: rem(18px);
    color: $black;
    margin-right: 0.78571rem;
    padding: 0.35714rem 0.78571rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__topic-box__share {
    background-color: white;
    width: rem(36px);
    height: rem(36px);
    border-radius: rem(4px);
    color: $white;
    font-size: rem(22px);
    text-align: center;
    line-height: 0;
    top: rem(5px);
    right: rem(5px);
    border: none;
    cursor: pointer;

    &:disabled {
      background-color: $bodyTextLighter;
      cursor: default;
    }
  }
  &__topic-box__scroll-container {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 40rem;
    padding-right: rem(62px);
  }
  &__topic-box {
    border-radius: rem(7px);
    border: 1px solid $grey13;
    &.people-picker {
      padding: rem(8px) rem(54px) rem(8px) rem(8px);
    }
    background: $white;
    overflow: hidden;
    height: rem(101px);
  }
  &__topic-box__scroll {
    right: rem(9px);
    bottom: rem(11px);
    background-color: $bodyColorMobile;
    width: rem(26px);
    border-radius: rem(4px);

    button {
      display: block;
      background: none;
      padding: 0;
      border: none;
      line-height: normal;
      cursor: pointer;

      &:disabled {
        cursor: default;
        background: none;
        border: none;
      }
    }

    i {
      font-size: rem(22px);
      display: block;
    }
  }
  &__credentialNameHeader {
    font-weight: 700;
    font-size: rem(24px);
    font-family: "Henderson BCG Serif";
    font-style: normal;
  }
  h4 {
    font-family: "Henderson BCG Sans";
    font-style: normal;
    font-weight: 700;
    font-size: rem(14px);
    text-transform: uppercase;
    margin-bottom: rem(10px);
  }
  a {
    color: $black;
    &:hover {
      text-decoration: underline;
    }
  }
  &__backButton {
    border-bottom: 2px solid $bodyColorMobile;
    padding-bottom: rem(25px);
    margin-bottom: rem(10px);
    margin-left: rem(15px);
    > span {
      font-weight: 400;
      font-size: 1rem;
      line-height: rem(21px);
      color: $grey15;
      font-family: "Henderson BCG Sans";
      > img {
        margin-right: rem(10px);
      }
    }
  }

  &__credentialName {
    font-weight: 700;
    font-size: rem(32px);
    line-height: 117%;
    font-family: "Henderson BCG Serif";
  }
}

.ui.label.credentialsPopup__hiddenTag {
  padding: rem(4px) rem(20px);
  background: $grey18;
  border-radius: rem(8px);
  width: rem(143px);
  font-weight: 400;
  font-size: rem(10px);
  color: $white;
  line-height: rem(15px);
  text-transform: uppercase;
}

.ui.label.credentialsPopup__expiredTag {
  background: $secondaryRed;
  color: $grey15;
  width: rem(145px);
}

.ui.modal.credentialsPopup {
  > .content {
    min-height: rem(690px);
    max-height: fit-content;
    padding-left: rem(50px);
    padding-right: 3.5rem;
    padding-top: rem(40px);
    & > div > .ui.grid {
      width: 75%;
    }
    h4.credentialsPopup__mandatoryField {
      &:after {
        color: red;
        content: "*";
      }
    }
  }
  > .actions {
    border-top: 2px solid $bodyColorMobile;
    margin-left: rem(16px);
    margin-right: 3.5rem;
    > .button {
      border: 1.5px solid $calendarGreen;
      font-size: 1rem;
      font-weight: 400;
      background: white;
      text-transform: uppercase;
      line-height: rem(21px);
      color: $calendarGreen;
      height: rem(40px);
      min-width: 6.5rem;
      padding: 0.51429rem 0.71429rem;
      margin-right: rem(-15px);
      &:hover {
        border: 2px solid $primaryDarkestGreen;
        color: $primaryDarkestGreen;
      }
      &.primary {
        background: $calendarGreen;
        color: white;
        margin-left: rem(30px);
        &:hover {
          background: $primaryDarkestGreen;
        }
      }
    }
  }
}

.ui.grid > .row.course__row {
  min-height: rem(115px);
  padding-bottom: 0;
}

.warn__message {
  display: flex;
  align-items: center;
  padding-top: rem(10px);
  &--text {
    margin-left: rem(5px);
    color: $duplicateWarnRed;
    font-size: rem(12px);
    font-style: normal;
    font-weight: 400;
    line-height: rem(18px);
  }
}

.ui.input.warn__input {
  outline: rem(1px) solid $duplicateWarnRed;
  border: none;
  border-radius: rem(7px);
  & > input:active {
    border: none;
  }
  & > input:focus {
    border: none;
  }
}
