@import "../../../../styles/_functions.scss";
@import "../../../../styles/_colors.scss";

.member {
  &__highlightRow {
    border: 1px solid $cardBorder-green;
    background: rgba(220, 249, 227, 0.08);
    cursor: pointer;
  }
}
