@import "../../../styles/colors";
@import "../../../styles/functions";

.ui.grid .credentials__grid {
  &__dragDropCursor {
    cursor: grabbing;
  }
  &__customWidth {
    width: 103%;
  }
  &__row-item.row {
    border: rem(0.5px) solid $grey13;
    padding-bottom: 0.5rem;

    &.featured {
      background-color: $featuredBlue;
    }
    &.hidden,
    &.expired {
      background-color: $bodyColorMobile;
    }
    &.selected__row {
      background: $hoverGreen;
      border: 1px solid $calendarGreen;
      .external__link {
        background-color: $calendarGreen;
        color: $white;
        i.icon {
          color: $white;
        }
      }
      i.icon {
        color: $calendarGreen;
      }
    }

    .hidden__label {
      font-style: normal;
      font-weight: 400;
      font-size: rem(10px);
      line-height: rem(15px);
      color: $white;
      padding: rem(2px) rem(8px);
      background-color: $grey18;
      margin-bottom: 0.2rem;
      text-transform: uppercase;
      border-radius: rem(4px);
    }
    .expired__label {
      background: $secondaryRed;
      color: $grey15;
    }
  }

  &--no-items {
    height: rem(76px);
    border: 0.5px solid $grey13;
  }

  &--btn {
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
  }

  &--header {
    background-color: $bodyColorMobile;
    padding: rem(10px) 0;
    font-family: "Henderson BCG Sans";
    font-style: normal;
    font-weight: 400;
    font-size: rem(12px);
    line-height: rem(15px);
    color: $bodyText;
    margin-bottom: 0;
  }

  &__name {
    font-style: normal;
    font-weight: 700;
    font-size: rem(12px);
    line-height: rem(20px);
    display: flex;
    align-items: center;
    color: $bodyText;
  }

  &__description {
    font-family: "Henderson BCG Sans";
    font-style: normal;
    font-weight: 400;
    font-size: rem(12px);
    line-height: rem(18px);
    margin-top: 0.5rem;
    color: $bodyText;
    width: 85%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: rem(18px);
  }
}

.no__credentials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  color: $grey18;
}

.credentials__grid__hiddenPopupMessage {
  & > {
    .ui.top.center.tiny.inverted.popup.transition.visible {
      font-weight: 300;
      max-width: rem(305px);
      font-size: rem(12px);
      line-height: rem(18px);
    }
  }
}
