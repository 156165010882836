@import "../../../styles/colors";
@import "../../../styles/functions";

.input__dd {
  width: 100%;
  height: rem(40px);
  position: relative;
  color: $bodyText;
  font-family: "Henderson BCG Sans";
  font-size: rem(14px);
  line-height: rem(24px);
  border: rem(1px) solid $grey13;
  border-radius: rem(7px);
  background-color: $white;
  padding-left: rem(8px);
  padding-right: calc(100% - 85%);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: start;

  &::after {
    position: absolute;
    content: url(../../../assets/icons/chevron-down-black.svg);
    top: calc(100% - 100% / 2 - 12px);
    color: $bodyText;
    left: calc(100% - 20px);
  }

  &__invalid {
    border-color: $warningYellow;
  }
}
