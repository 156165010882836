@import "./colors";
@import "./base";
@import "./functions";
@import "./layout";
@import "./component";

// only for demo purpose. should be deleted in future

.notification-bar {
  display: flex;
  color: #fff;
  background-color: #797979;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  // margin-top: 30px;
}

section section {
  margin-bottom: 20px;
  @include border-radius(5px);
  width: 100%;
  @include card-shadow;
}

// Dummy css end
