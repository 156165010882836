@import "../../../styles/colors";
@import "../../../styles/functions";

.external__link {
  font-family: "Henderson BCG Sans";
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: rem(4px) rem(12px);
  background-color: $white;
  outline: 1px solid $calendarGreen;
  border-radius: 0;
  color: $calendarGreen;
  cursor: pointer;
  border: none;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  font-size: rem(12px);

  &__text {
    line-height: rem(18px);
    margin-right: rem(7px);
  }

  i.icon {
    :hover {
      color: $white;
    }
  }
}
