@import "../../../styles/functions";

.avatar {
  border-radius: 50%;
  object-fit: cover;
  height: rem(71px);
  width: rem(71px);
}

@media (max-width: 767px) {
  .avatar {
    &--medium {
      height: rem(64px);
      width: rem(64px);
    }
  }
}
