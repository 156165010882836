@import "../../../../styles/colors";
@import "../../../../styles/functions";

.tag {
  background-color: $bodyColorMobile;
  border-radius: rem(16px);
  color: $bodyText;
  display: inline-block;
  line-height: rem(18px);
  font-size: rem(12px);
  font-weight: 400;
  border: 1px solid $grey18;
  margin-right: rem(8px);
  padding: rem(4px) rem(16px);
  max-width: rem(220px);
  white-space: nowrap;
  text-overflow: ellipsis;

  &:last-child {
    margin-right: 0;
  }
}
