@import "../../styles/colors";
@import "../../styles/functions";

.ui.header.update-overview__header {
  font-size: 2.286rem;
  font-family: "Henderson BCG Serif";
  margin-bottom: 8px;
}

.ui.card.profile__overview > .content {
  p.update-overview {
    &__statHeading {
      font-size: 2.285rem;
      line-height: 2.857rem;
      text-align: center;
      margin: 0 0;
      color: $bodyText;
    }
    &__statDesc {
      text-align: center;
      color: $bodyTextLighter;
      font-size: 1rem;
      line-height: 1.714rem;
      margin-bottom: 0.357rem;
    }
  }
  > .meta {
    display: flex;
    & span {
      font-size: rem(14px);
      font-style: normal;
      font-weight: 400;
      line-height: rem(21px);
      color: $grey18;
    }
  }
}

.update-overview {
  // margin: 2.357rem 0 1.429rem;
  width: 100%;
  // padding: 2.143rem 1.714rem 2rem 1.714rem;

  @media (max-width: 767px) {
    padding: 1rem 0.3rem 2rem;
    margin-top: 0;
  }
  @media (max-width: 360px) {
    padding: 1rem 0 2rem;
  }

  &__profileHeading {
    font-size: 1rem;
    margin-bottom: 0.357rem;

    &--incomplete {
      color: $updateOverviewIncomplete;
    }
    &--complete {
      color: $updateOverviewComplete;
    }
    &--last-updated {
      margin-left: 0.4rem;
      border-left: 0.1rem solid $bodyTextLighter;
      padding-left: 0.4rem;
    }
  }

  // &__tooltip {
  //   padding: 0 0.5rem 0.2rem;

  //   @media (max-width: 360px) {
  //     padding: 0 0.3rem 0.2rem;
  //   }

  //   p {
  //     margin: 0;
  //   }
  //   ol {
  //     margin-top: 0.5rem !important;
  //     margin-left: 0.5rem !important;
  //   }
  //   li {
  //     font-size: 1rem;
  //     line-height: 1.428rem !important;
  //   }
  //   li:before {
  //     color: $white !important;
  //     margin-left: -0.6rem !important;
  //   }
  // }
}
