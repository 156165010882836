@import "../../../../styles/functions";
@import "../../../../styles/colors";

.ui.button.outlinedButton {
  padding: rem(0px);
  width: rem(94px);
  background: $white;
  height: rem(24px);
  border-radius: unset;
  text-transform: uppercase;
  color: $calendarGreen;
  font-size: rem(12px);
  line-height: rem(18px);
  border: 2px solid $calendarGreen;
  letter-spacing: unset;
  font-weight: 400;
  &:hover {
    border: 3px solid $primaryDarkGreen;
    color: $primaryDarkGreen;
    background: $white;
  }
}
