@import "../../../styles/colors";
@import "../../../styles/functions";

.description {
  font-weight: 400;
  font-size: rem(12px);
  line-height: rem(18px);
}

p.no__case-desc {
  display: flex;
  font-size: rem(12px);
  justify-content: start;
  color: $bodyTextLighter;
}
