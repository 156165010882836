@import "../../../styles/functions";
@import "../../../styles/colors";

.expertise-skills-container {
  & p {
    display: inline-block;
    border: 1px solid $grey18;
    background-color: $bodyColorMobile;
    border-radius: 20px;
    padding: 0px 12px 0px 8px;
    font-size: rem(12px);
    font-weight: 400;
    line-height: rem(18px);
    cursor: pointer;
  }
  & h4 {
    text-transform: uppercase;
    font-size: rem(10px);
    margin-bottom: 0;
    font-weight: 400;
    color: #535353;
    line-height: 18px;
  }

  & h4:last-of-type {
    margin-top: rem(15px);
  }
}
