@import "../../../styles/colors";
@import "../../../styles/functions";

.ui.grid {
  > .row.profileCompletionRow {
    padding: rem(5px) 0;
    > {
      div:nth-child(2) > {
        div {
          font-weight: 400;
          font-size: rem(12px);
          > span:first-child {
            font-weight: 700;
            font-size: rem(14px);
          }
          > span:nth-child(2) {
            font-weight: 700;
          }
          &.ui.bulleted.list {
            margin-left: 1.7rem;
            margin-top: rem(5px);
          }
        }
        div:nth-child(3) {
          margin-top: rem(20px);
          .button:first-child {
            margin-right: rem(10px);
          }
        }
      }
    }
  }
}

.profileCompletionRow {
  &__addButton {
    font-weight: 400;
    font-size: rem(12px);
    color: $calendarGreen;
    border: none;
    background: none;
    padding: 0;
  }
}
