@import "../../../styles/colors";
@import "../../../styles/functions";

.banner_contacts_email {
  &__popup {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    color: $primaryDarkerGreen;
    margin-top: rem(-3px);
    margin-bottom: rem(-3px);

    > span:first-child {
      border-bottom: 1px solid $primaryDarkerGreen;
      padding-right: rem(3px);
    }

    &--copy-container {
      margin-top: rem(3px);
      margin-right: rem(8px);

      &:hover {
        cursor: pointer;
      }
    }

    &--pipe-divider {
      height: rem(20px);
      border-left: 1px solid #e0e0e0;
      align-self: center;
      margin-left: rem(7px);
      margin-right: rem(7px);
    }
  }
}
