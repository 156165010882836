@import "../../../../styles/functions";

.text__limiter {
  font-size: rem(16px);
  line-height: rem(30px);

  &__additional {
    margin-top: rem(16px);
  }
}
