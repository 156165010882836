// Variables - Colors

$bodyColor: #fafafa !default;
$bodyColorMobile: #f2f2f2 !default;
$primaryGreen: #3fad93 !default;
$primaryDarkerGreen: #177b57 !default;
$primaryDarkGreen: #03522d !default;
$primaryDarkestGreen: #0e3e1b;
$calendarGreen: #197a56;
$black: #000 !default;

$navIconNormal: #d8d8d8 !default;
$navIconActive: #3fad93 !default;

$bodyText: #333 !default;
$bodyTextAlt: #3a3a3a !default;
$bodyTextLight: #979797 !default;
$bodyTextLighter: #b7b7b7 !default;

$white: #fff !default;
$btnEditCircular: #f4f7f9 !default;

$experienceImg: #4caf50 !default;
$updateOverviewIncomplete: #1991eb !default;
$updateOverviewComplete: #3ca46a !default;
$settingPopupListHover: #ebf6f4 !default;

$gray1: #707070;
$gray2: rgba(51, 51, 51, 0.4);
$gray3: rgba(51, 51, 51, 0.2);

$gray4: rgba(51, 51, 51, 0.8);
$gray5: #e4e4e9;
$gray6: rgba(242, 242, 242, 0.5);
$gray7: #e7e7e7;
$grey8: #ccc;
$grey9: #dededf;
$grey10: #d2d2d2;
$grey11: #c2c2c2;
$grey12: #f9f9f9;
$grey13: #d4d4d4;
$grey14: #aaaaaa;
$grey15: #323232;
$grey16: #535353;
$grey17: #f7f7f7;
$grey18: #b1b1b1;
$grey19: #333333;
$gray20: #d9d9d9;
$grey20: #e0e1e2;
$caseAside: #e6e6e6 !default;
$caseAltTextColor: #eaeaea !default;
$tableBorderProf: #ebebeb !default;
$tableActBorderProf: #f5f5f5 !default;
$toggleChkBoxNormal: #c5d0de !default;
$editorBorderDark: #cacaca !default;
$editorDarkText: #999999 !default;
$successGreen: #32c77f !default;
$errorRed: rgba(213, 0, 0, 0.6%);
$secondaryRed: #fce1dc;
$warningYellow: #ff9800;
$astrickRequired: #d50000;
$errorRed: rgba(213, 0, 0, 0.6%) !default;
$warningYellow: #ff9800 !default;
$comboBoxBorder: #dddbda !default;
$bannerGreen: #327c58 !default;
$red: red;
$backgroundGrey: #f5f5f5;
$lightGreen: #d8e8e2;
$warningBackground: #fffbe8;
$gray--blackish: #2b2826;
$cardBorder-green: #21bf61;
$btnDisabled: rgb(112, 112, 112);
$tr-background: #fafafb;
$linkedIn-text-blue: #0a477d;
$darkestGreen: #0e3e1b;
$featuredBlue: rgba(224, 241, 255, 0.4);
$hoverGreen: rgba(220, 249, 227, 0.8);
$featuredBlueBorder: #76b9f4;
$featuredBlueLabel: #0a477d;
$iconOrange: #ffb66c;
$progressIncomplete: #954b00;
$duplicateWarnRed: #d82216;
