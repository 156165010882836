@import "../../../styles/functions";
@import "../../../styles/colors";

.list {
  &__container {
    color: $bodyText;
    font-size: rem(16px);
  }

  &__title {
    font-size: rem(16px);
    line-height: rem(17px);
    margin-bottom: rem(10px);
  }

  &__group {
    list-style: none;
  }

  &__item {
    border-left: 1px solid $bodyText;
    line-height: rem(18px);
    padding: 0 rem(7px);

    &:first-child {
      border-left: none;
      padding-left: 0;
    }

    &--sm {
      border-color: $gray1;
      color: $gray1;
      font-size: rem(14px);
      line-height: rem(16px);
    }
  }

  &__text {
    line-height: rem(18px);
  }

  &__disabled {
    color: $bodyTextLighter;
  }
}
