@import "../../../styles/colors";
@import "../../../styles/functions";

.primary__button {
  font-family: "Henderson BCG Sans";
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: rem(10px) rem(24px);
  background: $calendarGreen;
  border-radius: 0;
  color: $white;
  cursor: pointer;
  border: none;
  font-style: normal;
  font-weight: 400;

  &:hover {
    background-color: $darkestGreen;
  }

  &__icon {
    margin-right: rem(5px);
    font-size: rem(16px);
  }

  &__text {
    font-size: rem(14px);
  }
}
