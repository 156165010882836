@import "../styles/functions";
@import "../styles/colors";

// Reusable components e.g. buttons, navbars, cards etc

//Popover/Popup

.ui {
  &.popup {
    padding: 0.6rem 0.5rem;
    max-width: 260px;
    box-shadow: 0 2px 3px 0 rgba(112, 110, 107, 0.5);

    &.inverted {
      background-color: $bodyText;
      box-shadow: none;

      &:before {
        background-color: $bodyText !important;
      }
    }

    .popup__list {
      margin-bottom: 0 !important;

      .item {
        color: $bodyText;
        padding: 0.6rem 0.8rem;
        font-size: 0.857rem;
        font-family: "Henderson BCG Sans";
        cursor: pointer;
        min-width: rem(234px);

        &:hover {
          background-color: $settingPopupListHover;
          font-weight: bold;
        }
      }
    }

    .popup__content--small {
      font-size: 0.857rem;
      line-height: 1.429rem;
      max-width: rem(200px);
    }
  }
}

.multiselect {
  .i {
    &.icon {
      &.caret.down {
        &::before {
          font-size: 1.1rem;
        }
      }
    }
  }
}

@media (min-width: 911px) {
  .mdScreenCustomeFilterDisplayMax {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 911px) {
  .mdScreenCustomeFilterDisplayMin {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ui {
    .card {
      &__edit-btn,
      &__edit-btn-small,
      &__edit-btn-exp {
        width: 2.643rem;
        height: 2.643rem;
        text-align: center;
        padding: 0;
        background: $btnEditCircular;

        &:hover,
        &:active,
        &:focus {
          background: $btnEditCircular;
        }
      }

      &__edit-btn-small {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .actions.tab__footer {
    padding-top: rem(15px) !important;
    padding-bottom: rem(15px) !important;
    background-color: $white !important;
    border-top: 1px $tableActBorderProf solid !important;
  }
}

.ui {
  &.popup.controlled-popup {
    padding: 0.6rem 0.5rem;
    max-width: rem(350px);
    background-color: $bodyText;

    p {
      line-height: rem(24px);
    }
  }
}

.icon-setting,
.banner-email {
  span {
    &.popup--open {
      filter: none;
    }
  }
}

//Card

.ui.card.no-border {
  border: none !important;
  box-shadow: none !important;
}

.ui {
  .card {
    margin: 0;
    margin-bottom: 1.714rem;
    @include border-radius(5px);
    width: 100%;
    @include card-shadow;
    padding: 1.2rem 0 0.714rem;
    &.loader {
      padding: 10px;
    }
    & > .content {
      padding-left: 1.714rem;
      padding-right: 1.714rem;
      border-top: 1px solid $bodyColorMobile;

      & > .header:not(.ui) {
        font-size: 2.286rem;
        font-family: "Henderson BCG Serif";
      }

      &--no-info {
        padding-top: 3.571rem;
        padding-bottom: 3.571rem;

        h4 {
          font-size: rem(16px);
          font-weight: 700;
          line-height: rem(26px);
          color: $grey18;
          font-family: "Henderson BCG Sans";
          margin-bottom: rem(5px);
        }
        button {
          height: rem(40px);
          font-size: rem(14px);
          padding: 0 rem(24px);
          font-weight: 400;
          line-height: rem(21px);
          background-color: $grey15;
          border-radius: 0;
        }
      }
    }

    .extra.center {
      color: $primaryGreen;
      font-size: 1rem;
      font-weight: 700;
      padding-top: 1.143rem;
      padding-bottom: 0.714rem;
      line-height: rem(24px);

      a,
      a:hover,
      a:active {
        color: $primaryGreen;
      }
    }

    &__edit-btn,
    &__edit-btn-small,
    &__edit-btn-exp {
      width: 2.643rem;
      height: 2.643rem;
      text-align: center;
      padding: 0;
      background: $btnEditCircular;

      &:hover,
      &:active,
      &:focus {
        background: $btnEditCircular;
      }
    }

    &__edit-btn-small {
      width: 2rem;
      height: 2rem;
    }
  }
}

//Button

.ui {
  &.button {
    font-family: "Henderson BCG Sans";
    font-weight: bold;
    font-size: rem(12px);
    outline: none;
    box-shadow: none !important;
    cursor: pointer;
    letter-spacing: rem(0.8px);

    &.primary,
    &.primary:hover,
    &.primary:focus,
    &.primary:active {
      background-color: $primaryDarkerGreen;
      border: 2px solid $primaryDarkerGreen;
      border-radius: 0.35714rem;
      color: $white;
      line-height: 1.42857rem;
      text-transform: uppercase;
      padding: 0.71429rem;
      min-width: 5.42857rem;
    }

    &.button-sm {
      min-width: rem(106px) !important;
      padding: 0.51429rem 0.71429rem !important;
    }

    &.secondary {
      background-color: $bodyText;

      &.basic,
      &.basic:hover,
      &.basic:focus,
      &.basic:active {
        background-color: $white !important;
        border: 2px solid $bodyText;
        box-shadow: none !important;
        border-radius: 0.35714rem;
        color: $bodyText !important;
        line-height: 1.42857rem;
        text-transform: uppercase;
        padding: 0.71429rem;
        min-width: 6.42857rem;

        &.inverted {
          background-color: transparent !important;
          border-radius: 0.35714rem;
          border-color: $white !important;
          box-shadow: none !important;

          &.basic:hover,
          &.basic:focus,
          &.basic:active {
            background-color: transparent !important;
            color: $white !important;
          }
        }
      }
    }

    &.btn {
      height: rem(24px);
      padding: 0 rem(12px) 0 rem(12px) !important;
      box-sizing: border-box;
      border-radius: rem(5px);
      text-transform: uppercase;
      font-size: rem(10px);

      &.delete {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $bodyText;
        border: rem(1px) solid $bodyText;
        background-color: $white;
      }

      &.play {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $primaryGreen;
        color: $primaryGreen;
        background-color: $white;
        min-width: rem(107px);
      }
    }
  }
}

// Navigation Active states

.active-Overview {
  span {
    border: $red;
    background-position: rem(30px) rem(27px) !important;
  }
}

.active-Cases {
  span {
    background-position: rem(29px) rem(32px) !important;
  }
}

.active-Materials {
  span {
    background-position: rem(30px) rem(26px) !important;
  }
}

.active-Expertise {
  span {
    background-image: url(../assets/icons/ic_exp-green.svg) !important;
  }
}

// Pagination
.ui.pagination {
  &.menu {
    box-shadow: none;
    background: none;
    border: none;

    &.first {
      .icon.item {
        &:first-child {
          display: none !important;
        }
      }
    }

    &.last {
      .icon.item {
        &:last-child {
          display: none !important;
        }
      }
    }

    .item {
      background: none;
      padding: 0;
      width: rem(32px);
      height: rem(32px);
      border: none;
      text-align: center;
      min-width: 1px;
      justify-content: center;
      align-self: center;
      font-family: "Henderson BCG Sans";
      outline: none;
      color: $gray1;

      &:hover {
        background: none;
      }

      &::before {
        display: none;
      }

      &.active {
        background: none;
        color: $bodyText;
        padding: 0;
        font-weight: bold;
      }
    }

    .icon {
      width: 40px !important;

      &.item {
        width: rem(40px);
        height: rem(40px);
        background: $primaryGreen;
        @include border-radius(5px);
        color: $white;
        margin: 0 0.5rem;
      }
    }
  }
}

// Component design specific fixes
.Local {
  + span {
    text-transform: lowercase;
  }
}

.mobile-popup-position-Phone {
  .ui {
    &.popup {
      min-width: rem(180px);
    }
  }
}

.update-overview-tooltip {
  min-height: rem(171px);
  display: block !important;

  .popup {
    &:before {
      top: 22px !important;
    }
  }
}

.profile-views-tooltip {
  display: block !important;

  .ui.popup.inverted {
    background-color: #1b1c1d;
  }
}

.ui.fluid.card.card-update-overview {
  padding-top: 3.63rem;
  background: none;
  box-shadow: none;

  .image.header,
  button {
    display: none;
  }

  .content--no-info {
    padding-top: 0;
  }

  .placeholder {
    > :before {
      background-color: $bodyColor;
    }

    .image.header:after {
      background-color: $bodyColor;
    }

    .line {
      background-color: $bodyColor;

      :after {
        background-color: $bodyColor;
      }
    }
  }
}

.card-about {
  .image.header,
  button {
    display: none !important;
  }

  .content--no-info {
    padding-top: 0 !important;
  }
}

.card-exp {
  .align-items-center {
    div {
      font-size: rem(26px);
    }
  }
}

.banner-placeholder {
  height: rem(130px);
  width: rem(130px);
  border-radius: rem(60px);
}

.banner-heading-placeholder {
  border-radius: 10px;
  background-color: $grey10 !important;

  .line {
    background-color: $grey11 !important;
  }

  &:last-child {
    width: 50%;
  }
}

.banner-meta-placeholder {
  border-radius: 10px;
  background-color: $grey11 !important;
  flex-basis: 20% !important;
  min-height: 1px !important;
  margin-bottom: 20px;
  width: 180px;

  .line {
    background-color: $grey10 !important;
  }
}

.update-overview-item-placeholder {
  border-radius: 10px;
  font-size: 0.5rem;
  background-color: $gray7 !important;
  flex-basis: 20% !important;
  min-height: 1px !important;
  margin-bottom: rem(10px);
  width: 60px;

  .line {
    background-color: $grey10 !important;
    height: 0 !important;
  }
}

.banner-meta-placeholder + .banner-meta-placeholder {
  margin-top: 0 !important;
}

.member-placehoder {
  height: rem(48px);
  width: rem(48px);
  border-radius: rem(50px);
  display: block;
}

.caseTeam-placeholder {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: rem(50px);
  display: block;
}

.dropdown-popup {
  z-index: 4000 !important;

  .inverted.popup {
    background-color: transparent;
  }
}

//sort drop down

.ui {
  .dropdown.sort__dropdown {
    & > .text {
      color: $bodyText !important;
      font-family: "Henderson BCG Sans";
      font-size: rem(12px);
      font-weight: bold;
      letter-spacing: 0;
      line-height: rem(40px);
      min-width: rem(110px);
      text-align: center;
    }
  }
}

.label-bar {
  margin-bottom: rem(17px);
}

.labels {
  background-color: rgba(216, 216, 216, 0.26);
  border-radius: rem(7px);
  padding: 0 rem(17px) 0 rem(17px);

  &__list {
    line-height: rem(21px);
    list-style: none;
    padding: 0;
    margin: rem(16px) 0;

    li {
      display: inline;
      margin-right: rem(25px);
      font-size: rem(14px);
      font-family: "Henderson BCG Sans";
      text-transform: uppercase;
      color: $bodyText !important;

      span {
        font-weight: bold;
        padding-right: rem(8px);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.ui.dropdown.filter__dropdown .menu,
.ui.dropdown.filter__dropdown .scrolling.menu {
  box-shadow: 0 0 rem(12px) 0 rgba(0, 0, 0, 0.4);
  max-height: rem(382px);
  border-radius: 0 rem(7px) rem(7px) rem(7px) !important;
  background-color: $white;

  &::-webkit-scrollbar {
    width: rem(5px);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bodyTextLighter;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
  }
}

.ui.dropdown.upward .menu {
  border-radius: rem(7px) rem(7px) rem(7px) 0 !important;
}

.sort__dropdown.ui.dropdown .menu {
  width: rem(150px);

  & > .item {
    color: $bodyText;
    font-size: rem(12px);
    font-weight: normal;
    letter-spacing: 0ch;
    line-height: rem(20px);
  }
}

.sort__dropdown.ui.dropdown .menu .active.item {
  font-weight: normal;
  background-color: $bodyColorMobile;
}

.sort__dropdown.ui.dropdown > .dropdown.icon {
  margin-left: rem(0px);
}

.sort__dropdown.ui.dropdown > .dropdown.icon:before {
  content: "\f0d7";
  font-size: rem(14px);
}

//filter dropdown

.ui {
  .dropdown.filter__dropdown {
    width: rem(150px);
    height: rem(39px);
    display: flex;
    align-items: center;
    justify-content: center;

    & > .text {
      color: $bodyText !important;
      font-family: "Henderson BCG Sans";
      font-size: rem(14px);
      font-weight: 400;
      letter-spacing: 0.8px;
      line-height: rem(15px);
    }
  }
}

.ui {
  .dropdown.filter__dropdown.filter__selected {
    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primaryGreen;
      font-size: rem(12px);
      font-family: "Henderson BCG Sans";
      font-weight: bold;
      background-color: $white;
      height: rem(23px);
      width: rem(23px);
      border-radius: 50%;
      border: 2px solid;
      content: attr(data-before);
      z-index: 1;
      margin-right: rem(8px);
    }
  }
}

//dropdown
.custom-dropdown.ui.dropdown {
  .menu {
    background-color: $gray4;

    & > .item {
      color: $white;
      font-size: rem(12px);
      line-height: rem(14px);
      font-weight: bold;
      padding: rem(12px) rem(16px) !important;

      &:hover {
        background-color: rgba(242, 242, 242, 0.15);
      }
    }
  }
}

.customCase-dropdown.ui.dropdown {
  .menu {
    background-color: white;
    border: 2px solid $cardBorder-green;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0;
    & > .item {
      color: black;
      font-size: rem(12px);
      line-height: rem(14px);
      padding: rem(12px) rem(16px);
      &:not(:last-child) {
        border-bottom: 2px solid rgba(34, 36, 38, 0.1);
      }
      &:hover {
        background-color: #f1eeea;
      }
    }
  }
}

.ui {
  .dropdown.filter__dropdown.active {
    background-color: $primaryGreen;

    & > .text {
      color: $white !important;
    }
  }
}

// bundle dropdown ellipses
.m-dropdown {
  .icon.ellipsis {
    color: $bodyTextLighter;
    cursor: pointer;
    font-size: rem(17px);

    &:hover {
      color: $primaryGreen;
    }
  }

  &.active {
    .icon.ellipsis {
      color: $primaryGreen;
    }
  }
}

.filter__dropdown.ui.dropdown .menu .selected.item {
  font-weight: bold;
  background: $white;
}

.filter__dropdown.ui.dropdown .menu {
  max-width: rem(212px);
  min-width: rem(212px);

  & > .item {
    white-space: pre-wrap !important;
    display: block;
    color: $bodyText;
    font-size: rem(12px);
    font-weight: normal;
    line-height: rem(20px);

    &:hover {
      font-weight: bold;
      background: $white;
    }
  }
}

.filter__dropdown.ui.dropdown > .dropdown.icon:before {
  content: "\f0d7";
  font-size: rem(14px);
}

.filter__dropdown.ui.dropdown.active > .dropdown.icon:before {
  content: "\f0d8";
  font-size: rem(16px);
  color: $white;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;

  i.icon {
    font-size: 1.1rem;
  }

  .label-text {
    text-transform: uppercase;
    font-weight: bold;
    color: $bodyTextLight;
  }
  &__drop-down {
    display: flex;
    align-items: center;
  }

  &__label {
    font-family: "Henderson BCG Sans";
    font-size: rem(14px);
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: rem(24px);
    text-transform: uppercase;
    color: $gray1;
    margin-right: rem(32px);
  }
}

.clipboard-popover {
  .content {
    font-size: 0.857rem;
  }
}

// modal

.ui.modal {
  .header.heading {
    color: $bodyText !important;
    font-family: "Henderson BCG Serif" !important;
    font-size: rem(32px) !important;
    font-weight: bold;
    line-height: rem(58px) !important;
    padding: rem(24px) rem(66px) !important;

    &.pr-3 {
      padding-right: 3rem !important;
    }

    &.pl-3 {
      padding-left: 3rem !important;
    }
  }

  .actions.footer {
    background-color: $white !important;
    padding: rem(24px) rem(66px) rem(32px) rem(66px) !important;

    &.pr-3 {
      padding-right: 3rem !important;
    }

    &.pl-3 {
      padding-left: 3rem !important;
    }
  }

  .content.desc {
    padding: rem(24px) rem(66px) !important;

    &.py-0 {
      padding: rem(20px) 0 !important;
    }

    &.pt-0 {
      padding-top: 0 !important;
    }
  }
}

.custom-modal.ui.modal {
  .close {
    top: 2rem;
    right: 1.5rem;
    z-index: 1;
    opacity: 1;
    font-size: 1.2em;
    width: 1.5rem;
    height: 1.5rem;
    background: $black;
    border-radius: 50%;
    line-height: rem(3px);
    color: $white;
  }

  .people-picker .menu {
    z-index: 1002;
  }
}

.custom-modal-small {
  max-width: rem(420px) !important;

  .content {
    text-align: right;
  }
}

.custom-modal-sm {
  max-width: rem(750px) !important;

  textarea {
    border-color: $bodyTextLighter !important;
  }
}

.custom-modal-editProfile.ui.modal.d-none {
  display: none !important;
}

.custom-modal.ui.modal.d-none {
  display: none !important;
}

.custom-modal-sm.ui.modal.d-none {
  display: none !important;
}

.modals + .modals {
  background: none !important;
}

// Topic paths popup
.topicPathFullTree {
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: rem(14px);
  color: $bodyText;
  letter-spacing: 0.09375rem;
  line-height: rem(28px);
  font-weight: bold;
  white-space: nowrap;
}

.topicPathLink {
  color: $bodyText;

  &:hover,
  &:focus,
  &:active {
    color: $bodyText;
  }
}

.separator {
  padding-top: 1rem;
}

.tag-pa-star {
  margin: 0px 0px 0px 0.5rem;
  font-size: 0.75rem;
  color: rgb(62, 173, 147);
  line-height: 0.09375rem;
  text-decoration: none;
}

.child {
  padding: 0px rem(16px) !important;
  font-size: rem(14px) !important;
  display: inline-block !important;
  font-weight: normal !important;
  margin-right: rem(10px) !important;
  border-radius: rem(20px) !important;
  background-color: $bodyColorMobile;
  font-weight: normal;
}

// checkbox

.ui.checkbox {
  &.checked,
  &.indeterminate {
    label {
      &:before {
        background: $primaryGreen !important;
        border-color: transparent !important;
      }

      &:after {
        color: $white !important;
        font-size: rem(10px);
      }
    }
  }

  &.toggle {
    label:before {
      width: 2.7rem;
      background-color: $toggleChkBoxNormal !important;
    }

    input:checked ~ label:before,
    input:focus:checked ~ label:before {
      background: $primaryGreen !important;
    }

    input:checked ~ label:after {
      left: 1.35rem;
    }
  }

  &.checked {
    opacity: 1;
  }
}

// table

.ui.table {
  border: none;

  a {
    color: $bodyText;
  }

  &.case-table {
    font-size: rem(12px);

    tr > th {
      border-bottom: none !important;
      text-transform: uppercase;
      color: $bodyText;
      background-color: $bodyColorMobile !important;
    }

    tr > td {
      border-top: none;
      line-height: rem(20px);
      padding: 1.25rem 0.78571429rem;

      &:last-child.action {
        .icon {
          color: $bodyTextLighter;
          font-size: 1.2rem;

          &:hover {
            color: $primaryGreen;
          }
        }

        .visible {
          .icon {
            color: $primaryGreen;
          }
        }
      }
    }

    tr > th,
    tr > td {
      &:first-child {
        padding-left: rem(40px);
      }

      &:last-child {
        padding-right: rem(40px);
      }
    }

    tbody {
      tr.even {
        background-color: $white;
      }

      tr.odd {
        background-color: $gray6;
      }
    }
  }

  &.caseTeam-table {
    font-size: rem(12px);
    tbody {
      tr {
        &:hover {
          border: 1px solid $cardBorder-green;
          background: rgba(220, 249, 227, 0.08);
          cursor: pointer;
        }
      }
      tr > td {
        > div > div > div {
          > .description {
            font-size: 10px;
            height: unset;
          }
          > .header > a > strong {
            font-weight: bold;
          }
        }

        border-bottom: 1px solid rgba(34, 36, 38, 0.1);
        &:first-child {
          border-left: 1px solid rgba(34, 36, 38, 0.1);
          width: 36%;
        }
        &:last-child {
          border-right: 1px solid rgba(34, 36, 38, 0.1);
          padding-right: rem(14px);
        }
        &:not(:first-child) {
          vertical-align: middle;
        }
        &:nth-child(2) {
          width: 28.5%;
        }
        &:nth-child(3) {
          width: 25%;
        }
      }

      display: block;
      height: rem(400px);
      overflow-y: auto;
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
    tr > th {
      text-transform: uppercase;
      color: $bodyText;
      background-color: $bodyColorMobile;
      letter-spacing: rem(1px);
    }

    tr > td {
      border-top: 1px solid rgba(34, 36, 38, 0.1);
      line-height: rem(20px);
      &:last-child.action {
        .icon {
          color: $bodyTextLighter;
          font-size: 1.2rem;

          &:hover {
            color: $primaryGreen;
          }
        }

        .visible {
          .icon {
            color: $primaryGreen;
          }
        }
      }
    }

    tr > th,
    tr > td {
      &:first-child {
        padding-left: rem(20px);
      }
    }
  }

  &.compact.very {
    background-color: $bodyColor;

    font-size: rem(12px);
    border-radius: 0;

    tr > th {
      border-bottom: 1px solid $caseAltTextColor !important;
      text-transform: uppercase;
      color: $gray1;

      .ui.checkbox {
        margin-right: rem(10px);
        top: rem(4px);
      }

      > span {
        position: relative;

        .icon {
          position: absolute;
          margin-left: rem(2px);

          &:first-child {
            top: rem(-2px);
          }
        }
      }
    }

    tr > td {
      border-top: none;
      font-weight: bold;
      color: $bodyTextLighter;

      .ui.checkbox {
        margin-right: rem(10px);
        vertical-align: middle;
      }
    }

    tbody {
      tr:first-child {
        td {
          padding-top: rem(10px);
        }
      }

      tr:last-child {
        td {
          padding-bottom: rem(10px);
        }
      }
    }
  }

  &.normal-table {
    font-size: rem(12px);

    tr > th {
      border-top: 1px solid $gray7 !important;
      border-bottom: 1px solid $gray7 !important;
      text-transform: uppercase;
      color: $gray1;
      background-color: transparent !important;

      letter-spacing: rem(0.8px);

      &.action {
        min-width: rem(82px);
      }

      &:first-child {
        min-width: rem(150px);
      }

      &:nth-child(3) {
        min-width: rem(150px);
      }
    }

    tr > td {
      border-top: none;
      line-height: rem(20px);
      // padding: 1.25rem 0.78571429rem; haris
      padding: rem(9px) 0.78571429rem;
      height: rem(58px);
      vertical-align: middle;

      &:first-child {
        font-size: rem(14px);
        font-weight: bold;
      }

      /*  &:last-child.action {
        .icon {
          font-size: 1rem;
          color: $bodyText;
        }
      } */
    }

    tr > th,
    tr > td {
      &:first-child {
        padding-left: rem(32px);
      }

      &:last-child {
        padding-right: rem(32px);
      }
    }
  }
}

.closed {
  display: none !important;
}

// Achievements section

.achievements__tab .ui.secondary.pointing.menu {
  color: $bodyText;
  font-family: "Henderson BCG Sans";
  font-size: rem(14px);
  font-weight: bold;
  letter-spacing: rem(1.5px);
  line-height: rem(20px);
  text-align: center;
}

.achievements__tab .ui.segment.active.tab {
  border: none !important;
  padding: 0;
}

.ui.segment.active.tab {
  margin: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.achievements__table--head {
  & > tr > th {
    color: $gray1 !important;
    font-family: "Henderson BCG Sans" !important;
    font-size: rem(12px) !important;
    font-weight: bold !important;
    letter-spacing: 0.8px !important;
    line-height: rem(20px) !important;
    margin: 0 !important;
    text-transform: uppercase !important;
    background-color: $white !important;
    padding: rem(10px) rem(15px) !important;
    border-top: 1px solid rgba(183, 183, 183, 0.3) !important;
    border-radius: 0 !important;

    &:first-child {
      padding-left: rem(20px) !important;
    }
  }
}

.achievements__table--body {
  & > tr > td {
    color: $bodyText !important;
    font-size: rem(12px) !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: rem(20px) !important;
    border: none !important;
    height: rem(58px) !important;
    word-spacing: 0 !important;
    padding-left: rem(15px) !important;
    padding-right: rem(15px) !important;
    text-overflow: unset !important;

    &:first-child {
      padding-left: rem(20px) !important;
    }

    & > a:hover {
      border-bottom: $bodyText 1px solid;
    }
  }
}

.actions.tab__footer {
  padding-top: rem(15px) !important;
  padding-bottom: rem(15px) !important;
  background-color: $white !important;
  border-top: 1px $tableActBorderProf solid !important;
}

// tabs
.ui.secondary.pointing.menu {
  background-color: $white;
  color: $gray1;
  font-weight: bold;
  font-size: rem(14px);
  line-height: rem(20px);
  padding-left: rem(5px);
  padding-right: rem(5px);
  margin: 0;
  border-bottom: 2px solid $bodyColorMobile;

  .item {
    padding: rem(12px) rem(35px);
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: rem(1.5px);
    line-height: rem(20px);

    &.active {
      border-color: $primaryGreen;
      border-width: 2px;

      &:hover {
        border-color: $primaryGreen;
      }
    }
  }
}

.ss-tabs .active.tab {
  max-height: rem(540px);
}

.active.tab {
  overflow-y: auto;
  overflow-x: hidden;
}

// progress bar color
.ui.progress.primary {
  background-color: $gray5;
  height: rem(4px);
  margin-bottom: rem(8px);

  .bar {
    background-color: $primaryGreen;
    background-image: linear-gradient($primaryGreen, $primaryDarkerGreen);
    border-radius: rem(2px);
    height: rem(4px);
  }
}

.td-label,
.header-subHeading,
.dd-none {
  display: none !important;
}

@media only screen and (min-width: 1400px) {
  .custom-modal-topic.ui.custom-modal.modal {
    width: 1260px !important;
  }

  .custom-modal-editProfile.ui.custom-modal.modal {
    width: 1140px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .custom-modal.ui.modal {
    width: 1024px !important;
  }
}

@media (max-width: 767px) {
  .ui.modal {
    .header.heading {
      padding-top: 2rem !important;
      line-height: rem(48px) !important;
      // padding: rem(24px) rem(66px) !important;

      &.pr-3 {
        padding-right: 1rem !important;
      }

      &.pl-3 {
        padding-left: 1rem !important;
      }
    }

    .actions.footer {
      // padding: rem(24px) rem(66px) rem(32px) rem(66px) !important;
      &.pr-3 {
        padding-right: 1rem !important;
      }

      &.pl-3 {
        padding-left: 1rem !important;
      }
    }
  }

  // popup table

  .ui.modal > .content {
    display: block;
    padding: 1rem !important;
  }

  .ui.modal > .header {
    padding-top: 2rem !important;
  }

  .ui.table:not(.unstackable) {
    thead {
      display: none !important;
    }

    tr {
      display: flex !important;
      flex-wrap: wrap;
      width: 100% !important;
      box-shadow: none !important;

      // align-items: flex-end;

      &.md-none {
        display: none !important;
      }

      &:first-child {
        padding-top: 0 !important;
      }

      td {
        width: 50% !important;
        font-weight: bold !important;
        height: auto !important;
        margin-bottom: 0 !important;
        border: none !important;
        padding: 0.25rem 1.143rem !important;
        box-shadow: none !important;
        flex-direction: column;
        align-items: flex-start !important;

        &.md-none {
          display: none !important;
        }

        &.full-width {
          width: 100% !important;
        }
      }
    }

    &.member-table {
      tr {
        td {
          font-weight: normal !important;
          color: $bodyTextLighter;
        }

        td.first {
          width: 85% !important;

          p {
            color: $bodyTextLighter;
            font-weight: normal;
          }
        }

        td.action {
          width: 15% !important;
        }

        td.full-width {
          text-align: center !important;
          border-top: 1px solid $bodyColorMobile !important;
          margin-top: 10px;
          padding-top: 11px !important;

          a {
            color: $bodyTextLighter;
            text-decoration: underline;
          }
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          td.full-width {
            text-align: start !important;
            margin-left: 10%;
          }
        }
      }
    }

    &.member-table-with-header {
      thead {
        display: block !important;

        tr th {
          width: 50% !important;
          font-weight: bold !important;
          height: auto !important;
          border-bottom: 1px solid $gray3 !important;
          margin-bottom: 0.25rem !important;
          padding: 0.5em 0.75em 1rem !important;
          box-shadow: none !important;
          flex-direction: column;
          align-items: flex-start !important;

          &.md-none {
            display: none !important;
          }

          &.full-width {
            width: 100% !important;
          }
        }
      }
    }

    &.normal-table {
      tr {
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(34, 36, 38, 0.1);
      }
    }
  }

  .td-label {
    display: block !important;
    font-weight: normal;
    margin-top: 6px;
    color: $bodyTextLight;
  }

  .modal_footer,
  .education_footer,
  .tab__footer,
  .header-subHeading,
  .arrow-mobile {
    display: block !important;
  }

  .card__edit-btn,
  .card__edit-btn-small,
  .md-none {
    display: none !important;
  }

  .ss-tabs {
    overflow: hidden;

    .ui.secondary.pointing.menu {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  .ui.secondary.pointing.menu .item {
    padding: 1rem;
    font-size: rem(12px);
  }

  .ui.table .achievements__table--body tr:first-child {
    padding-top: 1rem !important;
  }

  .labels.lables-mobile {
    background-color: transparent;
    padding: 0;
    margin: -24px 0 15px;
    display: block !important;
  }

  .card.custom-card .card-content {
    padding: 1rem 1.143rem !important;
  }

  .card.custom-card .card-content--x,
  .card.custom-card .card-heading {
    padding: 0 1.143rem !important;
    display: block;
  }

  .topicPathFullTree {
    white-space: normal;

    &* {
      margin-bottom: 10px;
    }
  }

  .mmt-1 {
    margin-top: 1rem !important;
  }
}

@media (max-width: 767px) {
  .ui {
    &.card {
      &.profile-summary {
        margin-top: 0;
      }

      & > .content {
        padding-left: 1.143rem;
        padding-right: 1.143rem;
      }
    }

    &.popup {
      .popup__list {
        .item {
          min-width: 195px;
        }
      }
    }

    // modal
    &.dimmer {
      padding: 0;
    }

    &.custom-modal.modal,
    &.popup-modal.modal {
      border-radius: 0 !important;
      margin: 0 !important;
      width: 100%;
      min-height: 100vh;

      & > .actions {
        padding: rem(32px) rem(12px) !important;
      }
    }
  }

  // Component design specific fixes - Mobile
  .mobile-popup-position-Phone {
    margin-left: 1.643rem;
  }

  .mobile-popup-position-Settings {
    margin-left: 5.8rem;
  }

  .Local {
    display: inline-block !important;
  }

  .card-update-overview {
    padding: 0.4rem 0;

    .content {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      padding-top: 0.75rem;
    }

    .placeholder {
      > :before {
        background-color: $bodyColorMobile;
      }

      .image.header:after {
        background-color: $bodyColorMobile;
      }

      .line {
        background-color: $bodyColorMobile;

        :after {
          background-color: $bodyColorMobile;
        }
      }
    }
  }

  .banner-placeholder {
    height: 80px;
    width: 80px;
  }

  .phone-tip[data-tooltip]:hover:after {
    transform: translateX(-20%) scale(1) !important;
  }
}

// custom padding for cards
.custom-card {
  padding: 0 !important;

  .card-content {
    padding: rem(24px) rem(32px) !important;

    &--x {
      padding: 0 rem(32px) !important;
    }
  }

  .card-filter {
    padding: 0 rem(32px) !important;
    margin-bottom: rem(24px);
  }

  .card-heading {
    cursor: pointer;
    padding-left: 0 rem(5px) 0 0;
    margin-left: rem(32px);
    margin-bottom: rem(18px);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: start;

    h2 {
      margin: 0 !important;
      font-size: rem(24px);
    }
  }

  .header {
    font-size: rem(32px) !important;
    line-height: rem(48px) !important;
    margin: 0 !important;
  }
}

// Toast Message

.ui-alerts {
  z-index: 9999 !important;

  &.top-right {
    top: rem(20px) !important;
    right: rem(20px) !important;
    padding: 0 !important;
  }

  .header {
    color: $bodyText !important;
  }

  .floating {
    background-color: $white !important;
    color: $bodyText !important;
    box-shadow: 0 0 5px $grey8 !important;
    padding: 1rem 2.5rem 1rem 1rem;
  }

  .success {
    .icon:not(.close) {
      color: $primaryGreen;
    }
  }

  .error {
    .icon:not(.close) {
      color: $astrickRequired;
    }
  }

  .close.icon {
    top: 0.25em !important;
    right: 0.5em !important;
    opacity: 1 !important;
  }

  .icon .icon:not(.close) {
    opacity: 1 !important;
    margin-right: 1rem !important;
    width: 24px !important;
    height: 24px !important;
    font-size: rem(24px) !important;
  }
}

// Text editor override

._se_command_bold svg,
._se_command_italic svg,
._se_command_underline svg {
  height: 13px !important;
}

.sun-editor {
  border: 1px solid $bodyColorMobile !important;
  border-radius: 5px !important;

  .se-wrapper-inner {
    border-radius: rem(5px) rem(5px) 0 0 !important;
    margin-bottom: rem(20px);
  }

  .sun-editor-editable {
    a {
      color: $primaryGreen;
    }
  }

  .se-container {
    padding-bottom: rem(40px);
  }

  .se-btn {
    // border: 1px solid $gray3 !important;

    svg {
      fill: $navIconNormal;
    }

    &:enabled.active {
      svg {
        fill: $primaryGreen !important;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background: none !important;
      box-shadow: none !important;
    }
  }

  .se-btn-module-border {
    border: 1px solid $navIconNormal !important;
    border-radius: 5px !important;

    &:first-child {
      border: none !important;

      .se-menu-list li:last-child {
        .se-tooltip-text {
          text-transform: lowercase;

          &::before {
            content: "Hyper";
            text-transform: initial;
          }
        }
      }
    }
  }

  .se-tooltip.on {
    background-color: transparent !important;
  }

  button.se-btn-select {
    padding: 1px 6px;
    align-items: center;
    height: auto;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
    }

    &:after {
      content: "\f0d7";
      font-family: "Icons";
      font-size: 1.25rem;
      color: $editorDarkText !important;
    }
    &.se-btn-tool-format {
      width: 92px;
    }
    .txt {
      color: $editorDarkText;
    }

    svg {
      display: none;
    }
  }

  .se-btn-list {
    background-color: $white !important;
    box-shadow: none !important;
    border: none !important;
  }

  .se-toolbar {
    background: none !important;
    outline: none !important;
    position: absolute !important;
    bottom: 0;
    border-top: 1px solid $bodyColorMobile;
  }

  .se-toolbar-separator-vertical {
    background-color: $bodyColorMobile;
    width: 1px !important;
    height: 49px !important;
    margin: -5px 15px 0 !important;
  }

  .se-resizing-bar {
    display: none !important;
  }

  .se-dialog-close svg {
    fill: $bodyText !important;
  }
}

.editor-focused {
  .sun-editor {
    border: 1px solid $primaryGreen !important;

    .se-btn {
      svg {
        fill: $gray1;
      }
    }

    .se-btn-select {
      .txt {
        color: $gray1 !important;
      }

      &:after {
        color: $gray1 !important;
      }
    }

    .se-toolbar {
      background: none !important;
      outline: none !important;
      position: absolute !important;
      bottom: 0;
      border-color: $editorBorderDark;
    }

    .se-toolbar-separator-vertical {
      background-color: $editorBorderDark;
    }
  }
}

.se-dialog-form-footer label:last-child,
._se_bookmark_button {
  display: none !important;
}

.se-btn-primary,
.se-btn-primary:hover,
.se-btn-primary:active,
.se-btn-primary:focus {
  background-color: $white !important;
  border: 2px solid $bodyText !important;
  box-shadow: none !important;
  border-radius: 0.35714rem;
  color: $bodyText !important;
  line-height: 1.42857rem;
  text-transform: uppercase;
  padding: 0.71429rem;
  min-width: 6.42857rem;
}

.profiles-green {
  color: $primaryGreen !important;
}

.profiles-yellow {
  color: $warningYellow !important;
}

.edit-profile-tooltip,
.tags-tooltip,
.case-tooltip,
.orgMember-tooltip,
.picker-tooltip,
.clipboard-tooltip {
  z-index: 4000 !important;
}

// Enrich global nav default fixes
body {
  .ui.grid > .row {
    margin: 0;
    flex: auto;
  }

  .ui.dimmer {
    z-index: 4000;
    transition: none !important;
  }
}

// Topics/Skills serach rest box
.ui.divided.list.searchResult {
  padding-right: 1rem;

  > .item {
    border-top: 1px solid $bodyText;
    cursor: pointer;

    &:first-child {
      border-top: none;
    }

    > .content {
      padding-top: 5px;
      padding-bottom: 5px;

      > .header {
        color: #1b1c1d !important;
      }
    }
  }
}

// edit-profile - proposal-bio
.edit-profile-radioGroup {
  & > div {
    padding: 0 3em 0 0 !important;

    & .ui.checkbox.checked label {
      &:before {
        background: $white !important;
        border-color: $black !important;
      }

      &:after {
        background: $primaryGreen !important;
        border-color: $black !important;
      }
    }

    & .ui.checkbox label {
      &:before {
        background: $white !important;
        border-color: $bodyText !important;
      }
    }
  }
}

.edit-profile-optDropdown {
  & .ui.dropdown .menu > .item:first-child {
    padding: 0.8em 1.14285714rem 0.4em 0.8em !important;
  }

  & .ui.dropdown .menu > .item:last-child {
    padding: 0.4em 1.14285714rem 0.8em 0.8em !important;
  }

  & .ui.dropdown.upward .menu {
    border-radius: 7px 7px 7px 7px !important;
  }
}

// education-view-all modal
.education__table--head {
  & > tr > th {
    color: $gray1 !important;
    font-family: "Henderson BCG Sans" !important;
    font-size: 12px !important;
    font-weight: bold !important;
    letter-spacing: 0.8px !important;
    line-height: 20px !important;
    padding: 0 !important;
    margin: 0 !important;
    text-transform: uppercase !important;
    background-color: $white !important;
    padding: 10px !important;
    border-top: 1px solid rgba(183, 183, 183, 0.3) !important;
    border-radius: 0 !important;

    &:first-child {
      padding-left: 20px !important;
    }
  }
}

.education__table--body {
  & > tr > td {
    color: $bodyText !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: 20px !important;
    border: none !important;
    height: 58px !important;
    word-spacing: 0 !important;

    &:first-child {
      padding-left: 20px !important;
    }

    & > a:hover {
      border-bottom: $bodyText 1px solid;
    }
  }
}

.education_footer.tab__footer {
  padding-top: rem(20px) !important;
  padding-bottom: rem(20px) !important;
}

// update-history modal
.update-history__table--head {
  & > tr > th {
    color: $gray1 !important;
    font-family: "Henderson BCG Sans" !important;
    font-size: 12px !important;
    font-weight: bold !important;
    letter-spacing: 0.8px !important;
    line-height: 20px !important;
    padding: 0 !important;
    margin: 0 !important;
    text-transform: uppercase !important;
    background-color: $white !important;
    padding: 10px !important;
    border-top: 1px solid rgba(183, 183, 183, 0.3) !important;
    border-radius: 0 !important;

    &:first-child {
      padding-left: 20px !important;
    }
  }
}

.update-history__table--body {
  & > tr > td {
    color: $bodyText !important;
    font-size: 12px !important;
    // font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: 20px !important;
    border: none !important;
    height: 56px !important;
    word-spacing: 0 !important;

    &:first-child {
      padding-left: 20px !important;
    }

    & > a:hover {
      border-bottom: $bodyText 1px solid;
    }
  }
}

.update-history__table--footer {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: rem(16px) !important;
  margin-top: rem(-12px) !important;
}

// Hyperlink

.ui.left.labeled.input.hyperlink {
  width: 100%;
  & > input {
    box-sizing: border-box;
    border: 1px solid $grey13;
    border-radius: rem(7px);
    color: $bodyText;
    font-family: "Henderson BCG Sans";
    font-size: rem(14px);
    letter-spacing: 0;
    line-height: rem(24px);
    height: 40px;
    padding-right: 40px;
    width: 100%;
    display: block;
  }

  &.error::after {
    content: " ";
    height: rem(20px);
    width: rem(21px);
    background-color: $errorRed;
    background-image: url(../assets/icons/add.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: rem(13px) rem(13px);
    border-radius: 50%;
    position: absolute;
    top: rem(11px);
    left: calc(100% - 30px);
    transform: rotate(315deg);
  }

  &::after {
    content: " ";
    height: 20px;
    width: 21px;
    background-color: $successGreen;
    background-image: url(../assets/icons/done.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: rem(10px) rem(8px);
    border-radius: 50%;
    position: absolute;
    top: rem(11px);
    left: calc(100% - 30px);
  }
}

.ui.left.labeled.input.hyperlink_blank {
  width: 100%;
  & > input {
    box-sizing: border-box;
    border: 1px solid $grey13;
    border-radius: rem(7px);
    color: $bodyText;
    font-family: "Henderson BCG Sans";
    font-size: rem(14px);
    letter-spacing: 0;
    line-height: rem(24px);
    height: 40px;
    padding-right: 40px;
    display: block;
  }
}

.ui.input.error {
  & > input {
    background-color: $white;
    color: $bodyText;
  }
}

// tabular view modal
.modal__table--head {
  & > tr > th {
    color: $gray1 !important;
    font-family: "Henderson BCG Sans" !important;
    font-size: 12px !important;
    font-weight: bold !important;
    letter-spacing: 0.8px !important;
    line-height: 20px !important;
    padding: 0 !important;
    margin: 0 !important;
    text-transform: uppercase !important;
    background-color: $white !important;
    padding: 10px !important;
    border-top: 1px solid rgba(183, 183, 183, 0.3) !important;
    border-radius: 0 !important;

    &:first-child {
      padding-left: 20px !important;
    }
  }
}

.modal__table--body {
  & > tr > td {
    color: $bodyText !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: 20px !important;
    border: none !important;
    height: 58px !important;
    word-spacing: 0 !important;

    &:first-child {
      padding-left: 20px !important;
    }

    & > a:hover {
      border-bottom: $bodyText 1px solid;
    }
  }
}

.modal_footer.tab__footer {
  padding-top: rem(20px) !important;
  padding-bottom: rem(20px) !important;
}

// datepicker
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker {
  border: 1px solid $grey13 !important;

  &__input-container {
    & > input {
      padding-top: rem(7px);
      padding-bottom: rem(7px);
      padding-left: rem(12px);
      border: 1px solid $grey13;
      border-radius: rem(7px);
      width: 100%;
      color: $bodyText;
      font-family: "Henderson BCG Sans";
      font-size: rem(14px);
      letter-spacing: 0;
      line-height: rem(24px);
      cursor: pointer;
    }

    &::before {
      content: url(../assets//icons/Calendar.svg);
      position: absolute;
      top: rem(10px);
      left: 90%;
      pointer-events: none;
    }
  }

  &__day--selected {
    background-color: $calendarGreen !important;
  }

  &__year-select {
    max-height: 100px;
  }

  &__year-option {
    padding: 5px 0;

    &:hover {
      background-color: rgba(0, 0, 50, 0.07) !important;
    }
  }

  &__navigation--years-upcoming {
    border-bottom-color: $bodyText !important;
  }

  &__navigation--years-previous {
    border-top-color: $bodyText !important;
  }

  &__header {
    background-color: $calendarGreen !important;
    border-bottom: 1px solid $primaryGreen !important;
  }

  &__day-name,
  &__current-month,
  &__year-read-view {
    color: $white !important;
  }

  &__navigation--previous {
    border-right-color: $white !important;
  }

  &__navigation--next {
    border-left-color: $white !important;
  }

  &__year-read-view--down-arrow {
    border-top-color: $white !important;
  }

  &__day--keyboard-selected,
  &__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected,
  &__year-text--keyboard-selected {
    background-color: $calendarGreen !important;
  }

  &__year-dropdown {
    background-color: $white !important;
  }
}

.react-datepicker-popper {
  z-index: 4000 !important;
}

// awards dropdown

.dd__awards {
  position: relative;

  & > select {
    position: relative;
    box-sizing: border-box;
    height: rem(40px);
    width: rem(163px);
    border: 1px solid $bodyTextLighter;
    border-radius: rem(4px);
    color: $bodyText;
    font-family: "Henderson BCG Sans";
    font-size: rem(14px);
    letter-spacing: 0;
    line-height: rem(24px);
    background-color: $white;
    -moz-appearance: none;
    -webkit-appearance: none;

    & > option {
      color: $bodyText;
      font-family: "Henderson BCG Sans";
      font-size: rem(14px);
      letter-spacing: 0;
      line-height: rem(24px);
      background-color: $white;
      display: block;
      border: 1px solid $primaryGreen !important;
      padding: rem(20px);

      &:hover {
        border: 1px solid $bodyTextLighter !important;
        background-color: $bodyTextLighter !important;
      }
    }
  }

  &::after {
    content: "";
    height: rem(20px);
    width: rem(20px);
    background-color: $primaryGreen;
    position: absolute;
    top: rem(10px);
    left: rem(135px);
    z-index: 4000;
    pointer-events: none;
  }
}

select::-ms-expand {
  display: none !important;
}

.ui.dropdown.award-loaction__DD {
  min-width: rem(120px);
}

.custom__dropdown.ui.dropdown .menu {
  overflow: auto;
  max-height: rem(140px);
  height: auto;

  & > .item .text {
    color: $bodyText;
    font-family: "Henderson BCG Sans";
    font-size: rem(14px);
    letter-spacing: 0;
    line-height: rem(24px);
    display: block;
    white-space: normal;
  }
}

.link__dd {
  padding: rem(8px);
  width: rem(95px) !important;
  text-overflow: unset !important;
}

.awards__text-area {
  box-sizing: border-box;
  height: rem(40px) !important;
  width: 100% !important;
  border: 1px solid $grey13;
  border-radius: rem(7px);
  background-color: $white;
  color: $bodyText;
  font-family: "Henderson BCG Sans";
  font-size: rem(14px);
  letter-spacing: 0;
  padding: rem(7px) rem(12px);
  line-height: rem(24px);
}

.achievements__text-box,
.ui.fluid.input.achievements__text-box {
  width: 100%;

  & > input[type="text"] {
    box-sizing: border-box;
    height: rem(40px) !important;
    width: rem(183px) !important;
    border: 1px solid $grey13;
    border-radius: rem(7px);
    background-color: $white;
    color: $bodyText;
    font-family: "Henderson BCG Sans";
    font-size: rem(14px);
    letter-spacing: 0;
    padding: rem(7px) rem(12px);
    line-height: rem(24px);
  }
}

.astrisk__required {
  height: rem(14px);
  width: rem(7px);
  color: $astrickRequired;
  font-family: "Henderson BCG Sans";
  font-size: rem(12px);
  letter-spacing: 0;
  line-height: rem(14px);
  margin-left: rem(3px);
  margin-top: rem(-10px) !important;
}

// People picker

.people-picker {
  .input input {
    background: url("../assets/icons/search-Icon.svg") no-repeat 15px center !important;
    border-color: $comboBoxBorder !important;
    border-radius: rem(4px);
    padding-left: rem(46px);

    &:disabled::placeholder {
      color: transparent !important;
    }
  }

  .chip {
    padding: 2px 7px 0;
    background-color: $bodyColorMobile !important;
    position: absolute;
    top: 4px;
    left: 5px;
    border-radius: 4px;

    .delete {
      color: $white;
      background: $bodyTextLighter;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      line-height: 22px;
      opacity: 1;
      margin-top: 2px;
      cursor: pointer;
    }

    &.small {
      padding: 4px 7px;
      position: static;
      display: inline-flex;
      align-items: center;

      .delete {
        width: 20px;
        height: 20px;
        line-height: 20px;
        flex: 0 0 20px;
      }
    }
  }

  .menu {
    border: 1px solid $comboBoxBorder;
    border-top: none;
    list-style-type: none;
    max-height: 200px;
    overflow: auto;
    box-shadow: 0 2px 3px rgba(112, 110, 107, 0.5);
    border-radius: 0 0 4px 4px;
    position: absolute;
    width: 100%;
    background: $white;

    .item {
      border: none;
      padding: 15px 5px;
      cursor: pointer;
      font-size: 12px;

      &:hover {
        background-color: $black;
        color: $white;
      }

      .content:last-child {
        font-weight: bold;
      }
    }
  }
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text,
.ui.input > input::placeholder {
  color: $gray1;
  opacity: 0.8;
}

// Topic picker
.topic-picker {
  .input input {
    border-color: $comboBoxBorder !important;
    border-radius: 4px;

    &:disabled::placeholder {
      color: transparent !important;
    }
  }
}

//multi select filter dropdown

.multi-filter__dropdown.ui.dropdown .menu .selected.item {
  font-weight: bold;
  background: $white;
}

.multi-filter__dropdown.ui.dropdown .menu {
  max-width: rem(212px);
  min-width: rem(212px);
  box-shadow: 0 0 rem(12px) 0 rgba(0, 0, 0, 0.4);
  border-radius: 0 rem(7px) rem(7px) rem(7px) !important;

  & > .item {
    & > .ui.checkbox {
      white-space: pre-wrap !important;
      display: block;
      color: $bodyText;
      font-size: rem(12px);
      font-weight: normal;
      line-height: rem(20px);

      &:hover {
        font-weight: bold;
      }
    }

    &:hover {
      font-weight: bold;
      background: $white;
    }
  }

  & > .footer {
    padding-top: rem(10px);
    padding-bottom: rem(15px);
    padding-right: rem(15px);

    & > .btn__apply {
      border-radius: rem(5px);
      background-color: $btnDisabled;
      color: $white;
      font-size: rem(10px);
      font-weight: bold;
      letter-spacing: 0.8px;
      line-height: rem(12px);
      text-align: center;
      padding: 5px 20px 5px 20px;
      text-transform: uppercase;

      &.active {
        background-color: $primaryGreen;
        color: $white;
      }
    }

    & > .btn__clear {
      box-sizing: border-box;
      border: 2px solid $bodyText;
      border-radius: rem(5px);
      background-color: $white;
      color: $bodyText;
      font-size: rem(10px);
      font-weight: bold;
      letter-spacing: 0.8px;
      line-height: rem(12px);
      text-align: center;
      padding: 5px 20px 5px 20px;
      text-transform: uppercase;
      &.active {
        color: $primaryGreen;
        border: 2px solid $primaryGreen;
        &:hover {
          background-color: $white;
        }
      }
    }
  }

  & > .input {
    & input {
      box-sizing: border-box;
      border: 1px solid $bodyTextLighter;
      border-radius: 4px;
      background-color: $white;
      font-size: rem(12px);
      line-height: 1.3rem;
    }
  }

  .ui.checkbox .box,
  .ui.checkbox label {
    padding-left: rem(30px);

    &::before {
      border: 1px solid $bodyTextLight;
      border-radius: rem(3px);
    }
  }

  .ui.checkbox.checked {
    font-weight: bold;
  }
}

.multi-filter__dropdown.ui.dropdown > .dropdown.icon {
  margin-top: -5px;
}

.multi-filter__dropdown.ui.dropdown > .dropdown.icon:before {
  content: "\f0d7";
  font-size: 1.1rem;
}

.multi-filter__dropdown.ui.dropdown.active > .dropdown.icon:before {
  content: "\f0d8";
  font-size: 1.1rem;
  color: $white;
}

.ui.dropdown.multi-filter__dropdown .scrolling.menu {
  max-height: rem(382px);
  background-color: $white;
  border-top: none;

  &::-webkit-scrollbar {
    width: rem(5px);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bodyTextLighter;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
  }
}

.ui {
  .dropdown.multiple.multi-filter__dropdown {
    padding-right: 0;
  }
}

.ui {
  .dropdown.multi-filter__dropdown {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 135px;
    max-width: 135px;

    & > .text {
      color: $bodyText !important;
      font-family: "Henderson BCG Sans";
      font-size: rem(12px);
      font-weight: bold;
      letter-spacing: 0.8px;
      line-height: rem(20px);
    }
  }
}

.ui {
  .dropdown.multi-filter__dropdown.active {
    background-color: $primaryGreen;

    & > .text {
      color: $white !important;
    }
  }
}

.multi-filter__dropdown.ui.dropdown.filter__selected {
  &::before {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primaryGreen;
    font-size: rem(12px);
    font-family: "Henderson BCG Sans";
    font-weight: bold;
    background-color: $white;
    height: rem(23px);
    width: rem(23px);
    border-radius: 50%;
    border: 2px solid;
    content: attr(data-before);
    z-index: 1;
  }
}

.custom-modal-viewAllAchievements.ui.modal.d-none {
  display: none !important;
}

.mandatory-input-warning {
  &.ui.input {
    &.fluid.achievements__text-box,
    & {
      > input {
        border: 1px solid $red;
      }
    }
  }
}

// Pinned items override
.ui.modal > .actions {
  background-color: transparent !important;
}

.document + .separator {
  background-image: none !important;
}

.empty__title,
.empty__text,
.ui.large.loader,
.ui.large.loader + div,
.document-list * {
  font-family: "Henderson BCG Sans", Georgia, "Times New Roman", Times, serif !important;
  font-weight: 400 !important;
  color: $bodyText !important;
}

.empty__title,
.empty__text {
  font-size: 1.2rem !important;
  margin: 0 auto;
  text-align: center;
  line-height: 1.5 !important;
}

.ui.large.loader,
.ui.large.loader + div {
  margin-top: 20px;
  font-size: 1.2rem !important;
  line-height: 1.5 !important;
  font-weight: normal !important;
}

// docviz override

body .docviz-wrapper .docViz-overlay {
  z-index: 3030 !important;

  .slideSelection-action--1,
  .slideSelection-action--2 {
    display: none;
  }
}

.docviz-loader {
  box-shadow: none !important;
  border: none !important;
  margin: 0 !important;
  padding: 0.8em 0.8em !important;
}

// docviz override end

.warningpopupContent {
  color: $bodyText;
  font-weight: bold;
  display: flex;
  margin-bottom: rem(20px);
  text-align: left;
}

// assistant to
span.ui.label.assistant-to-info__extra-exec--launcher {
  color: $primaryDarkerGreen;
  position: relative;
  height: rem(14px);
  width: rem(13px);
  font-size: (14px);
  font-weight: bold;
  letter-spacing: 0;
  line-height: rem(14px);
  margin-top: rem(-6px);
  margin-left: rem(9px);
  padding: 0.5rem rem(1px) !important;
  cursor: pointer;
}

span.ui.label.assistant-to-info__extra-exec__popup-close-panel {
  position: absolute;
  right: 0;
  background-color: transparent !important;
  margin-top: -0.5em !important;
  margin-right: 0.5em !important;
  height: rem(12px) !important;
  width: rem(12px) !important;
}

.ui.label > img.assistant-to-info__extra-exec--popup__close {
  color: $bodyText;
  margin-left: 0 !important;
  width: 100% !important;
  height: 100% !important;

  &:hover {
    cursor: pointer;
  }
}

// proficiency indicator
.proficiency-tooltip {
  div {
    padding: rem(4px) rem(8px) !important;
    font-size: rem(12px) !important;
    text-align: center;

    &:before {
      left: 45% !important;
    }
  }
}

// banner, email copy tooltip
.banner-email__popup--copy__tooltip {
  div {
    margin-left: rem(15px);
    margin-right: rem(15px);
    color: $grey11;
  }
}

// banner, number copy tooltip
.banner-number__popup--copy__tooltip {
  div {
    margin: rem(-2px) rem(6px) !important;
    font-size: rem(12px) !important;
    color: $grey11;
  }
}

// popup loading segment
.ui.segment.loading__segment {
  box-shadow: none;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: none;
  height: 100%;
}

// taxonomy dropdown

.taxonomy__dropdown.ui.dropdown .menu {
  max-height: rem(488px);
  left: -5px !important;
  min-width: fit-content;
  height: auto;

  & > .item .text {
    color: $bodyText;
    font-family: "Henderson BCG Sans";
    font-size: rem(12px);
    letter-spacing: 0;
    line-height: rem(20px);
    display: block;
    white-space: normal;
  }
}

.ui.grid {
  &.taxonomy__selector {
    > .row {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.border-head {
  border-top: 1px solid $grey14 !important;
}

.item-background {
  background-color: $bodyColorMobile;
}

.item__background--lightgreen {
  background-color: $lightGreen !important;
  + div,
  i {
    color: $bodyText !important;
    background-color: $lightGreen !important;
  }
  &:hover {
    background-color: $lightGreen !important;
  }
}

.list__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $grey14;
  padding-left: rem(5px);
  background-color: $bodyColorMobile;
  margin-bottom: rem(2px);
  &:hover {
    background-color: $grey13;
  }
  & ~ div {
    background-color: $grey13;
  }
  &--disabled {
    background: $grey12;
    border: 1px solid $grey14 !important;
  }
}

.ui.card.taxonomy__card {
  box-shadow: none;
}

.loading__spinner {
  border: 3px solid $white;
  border-radius: 50%;
  border-top: 3px solid $bodyTextLight;
  margin-right: rem(5px);
  width: rem(15px);
  height: rem(15px);
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.warning {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $bodyText;
  line-height: 35px;
  font-size: rem(12px);
  padding: 2px;
  background-color: $warningBackground;
  i.icon {
    color: $warningYellow;
  }
  font-family: "Henderson BCG Sans";
}

@media screen and (max-width: 1280px) {
  .ui {
    .dropdown.sort__dropdown {
      & > .text {
        min-width: 7rem;
      }
    }
  }

  .flex_shrink {
    flex-shrink: 0;
  }

  .labels {
    padding: 0 0.6rem 0 0.6rem !important;
    flex-shrink: 0;

    &__list {
      li {
        margin-right: 0.6rem;

        span {
          padding-right: 0.27143rem;
        }
      }
    }
  }
}
