@import "../../../styles/colors";
@import "../../../styles/functions";

.people-picker {
  &__avatar {
    width: rem(28px);
    height: rem(28px);
    border-radius: 50%;
    padding-bottom: 0.18rem;
    padding-top: 0.14rem;
    margin-top: -0.1rem;
    padding-left: 0.16rem;
    padding-right: 0.16rem;
  }
  &__avatarName {
    font-size: rem(12px);
    color: $bodyText;
    line-height: 2;
    margin-right: rem(10px);
  }
  &__placeHolder {
    width: rem(100px);
    float: left;
    margin-right: rem(20px);
    margin-top: rem(9px);
  }

  &__singleItemPadding {
    padding: rem(3px) 0 rem(5px);
  }
}
