@import "../../../styles/colors";
@import "../../../styles/_functions";

.assistant-info {
  width: 150%;
  & > span {
    line-height: 1.5rem;
    font-weight: bold;
  }
  p {
    margin: 0;
    > span {
      margin-left: 0.5rem;
    }
  }
  a {
    display: inline-block;
    color: $white;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1264px) {
  .assistant-info {
    & > p {
      & > span {
        margin-left: 0;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .assistant-info {
    width: 100%;
  }
}
