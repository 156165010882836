@import "../../../styles/colors";
@import "../../../styles/functions";

.ui.card {
  > .content {
    .description__full {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .case {
    border: 1px solid $grey8;
    box-shadow: none;
    border-radius: rem(4px);
    &:hover {
      background-color: rgba(106, 250, 189, 0.08);
      border: 1px solid $primaryGreen;

      .ui.placeholder.expertise-loading,
      .ui.placeholder.expertise-loading .image.header:after,
      .ui.placeholder.expertise-loading .line,
      .ui.placeholder.expertise-loading .line:after,
      .ui.placeholder.expertise-loading > :before {
        background-color: rgb(233, 249, 241);
      }

      .case_view-details {
        & p {
          border-bottom: 2px solid $calendarGreen;
          cursor: pointer;
          &.disabled {
            border-bottom: none;
          }
        }
      }
    }
  }

  .case_view-details {
    display: flex;
    justify-content: center;
    padding: 12px;
    background-color: $grey17;
    border-radius: 4px;
    font-weight: bold;
    color: $calendarGreen;
    max-height: rem(43px);
    p.disabled {
      color: $bodyTextLighter;
      border-bottom: none;
    }
  }
}

.ui.grid > .row > .column {
  .case {
    margin-bottom: rem(10px);
    border-radius: 0;
    margin-bottom: 5px;
    &__caseDesc {
      color: $bodyTextAlt;
      font-size: rem(14px);
      line-height: rem(20px);
      margin-bottom: rem(20px);
      margin-top: rem(16px);

      a {
        color: $bodyText;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__caseMeta-readOnly {
      font-size: rem(12px);
      line-height: rem(18px);
      padding-left: 0;
      padding-bottom: rem(10px);
      align-items: baseline;
      flex-wrap: wrap;
      border-bottom: none;
      font-weight: 700;
      color: $grey15;
      span {
        margin: 0 rem(10px);
      }

      p {
        font-size: rem(12px);
        line-height: rem(16px);
      }
    }
    &__caseForm {
      height: 0;
      width: 0;
      clip: rect(0, 0, 0, 0);
      font-size: 0;
      position: absolute;
      border: none;
      text-indent: -999999;
    }
    &__tag-placeholder {
      height: 33px;
      width: 100px;
      border-radius: 20px;
    }
    &__caseId-readOnly {
      color: $bodyText;
      line-height: rem(21px);
    }
    &__caseHrs {
      justify-self: start;
      font-weight: normal;
      color: $bodyText;
      line-height: rem(22px);
      p:nth-child(2) {
        font-weight: 700;
        font-size: rem(16px);
        line-height: rem(20px);
        color: $grey16;
      }
    }
    &__expandBtn {
      color: $navIconActive;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: rem(10px);
      display: inline-block;
    }
    &__topic-box-item {
      font-size: rem(12px);
      color: $bodyTextAlt;
      display: inline-block;

      &:after {
        content: "|";
        padding-left: 7px;
      }

      &:last-child:after {
        display: none;
      }
    }
    &__tag-readOnly {
      padding: rem(7px) rem(16px);
      font-size: rem(12px);
      display: inline-block;
      font-weight: normal;
      margin-right: rem(10px);
      border-radius: rem(16px);
      max-width: 220px;
      white-space: nowrap;
      color: $black;
      font-weight: bold;
      font-weight: 400;
      line-height: rem(18px);
      border: 1px solid $grey18;
      padding: 5px 11px 5px 11px;
      background: $bodyColorMobile;
      &__no-tags {
        color: $bodyTextLighter;
      }
      &__overflow {
        text-overflow: ellipsis;
      }
    }
    &__topic-box-heading {
      margin-top: 18px;
      color: $gray1;
      margin-bottom: 7px;
      font-size: rem(12px);
    }

    &__case-header {
      display: flex;
      justify-content: start;
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        flex-direction: column;
      }
    }

    &__nocaseDesc {
      color: $bodyTextLighter;
      font-size: rem(14px);
      line-height: rem(20px);

      a {
        color: $bodyTextLighter;
        border-bottom: rem(1px) solid $bodyTextLighter;
      }
    }
    &__case_title {
      color: $grey15;
      font-size: rem(14px);
      line-height: rem(21px);

      a {
        color: $bodyText;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      &__caseMeta-readOnly {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.description__ellipses {
  position: absolute;
  top: 20px;
  right: -15px;
  display: inline-block;
  cursor: pointer;
  height: rem(15px);
  width: rem(15px);
}

.ui.grid > .row > .column > .case__main {
  display: flex;
  flex-direction: column;

  & div.row:first-child {
    margin-bottom: 20px;
  }

  & div {
    &.row:nth-child(3) {
      flex-direction: column;
      margin-bottom: auto;
    }
  }
}

.ui.grid > .row > .case__caseAside-readOnly {
  color: $gray1;
  font-size: rem(12px);
  line-height: rem(20px);
  font-weight: bold;
  padding-top: rem(18px);
  padding-bottom: rem(15px);
  display: flex;
  flex-direction: column;

  p,
  div {
    &:nth-child(1) {
      margin-bottom: auto;
      font-weight: 400;
      line-height: rem(18px);
      color: $grey16;
    }
  }
}

@media (min-width: 1160px) {
  .ui.grid > .case__customGrid {
    flex-wrap: nowrap;

    > div:first-child {
      max-width: 200px;
      min-width: 200px;
    }
    > div:nth-child(2) {
      min-width: calc(100% - 200px);
    }
  }
}

@media (max-width: 767px) {
  .case {
    &__tags-mobile {
      padding-top: 1.5rem;
      border-top: 1px solid $tableBorderProf;
    }
    &__caseId-readOnly {
      display: block;
      margin-bottom: 1rem;
    }
    :global(.custom-grid__topics) {
      padding-left: 1rem;
      border-top: 1px solid $tableBorderProf;
    }
  }
}
