@import "../../styles/_functions.scss";
@import "../../styles/colors";

.summary {
  &__description,
  &__language {
    margin-bottom: rem(32px);
  }
  &_walkMe {
    margin-left: rem(120px);
  }

  &__proposal {
    &__title {
      font-size: rem(16px);
      margin-bottom: rem(10px);
    }
    &__info {
      color: #696969;
      font-size: 11px;
      margin-bottom: 10px;
    }
    &__infoIcon {
      margin-bottom: 2px;
      margin-right: 4px;
    }
  }

  &__description,
  &__language,
  &__proposal {
    font-size: rem(16px);
    line-height: rem(18px);
  }

  &__disabled {
    color: $bodyTextLighter;
  }
}

.pr {
  color: $bodyText;

  &__item {
    display: flex;
    margin-bottom: rem(16px);
  }

  &__icon {
    margin-right: rem(9px);
  }

  &__content {
    flex: 1;
  }

  &__link {
    background: transparent;
    border: none;
    font-size: rem(16px);
    line-height: rem(20px);
    margin-bottom: rem(5px);
    outline: none;
    word-break: break-all;

    &:hover {
      text-decoration: underline;
    }
  }
}
