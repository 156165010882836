@import "../../../styles/_functions.scss";
@import "../../../styles/colors";

.proficiency-level {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  > span.ui.circular.label {
    min-height: rem(8px);
    min-width: rem(8px);
    height: rem(8px);
    width: rem(8px);
    font-size: rem(8px);
    margin: 0 rem(3px);
    opacity: 0.98;
  }
}

.label.proficiency-level {
  &__indicator {
    background-color: rgba(183, 183, 183, 0.62);

    &--enabled {
      background-color: $primaryGreen;
    }
  }
}
