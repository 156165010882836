@import "../../styles/colors";
@import "../../styles/functions";

.imp-user {
  background: $bodyText;
  color: $white;
  min-height: 102px;
  font-size: rem(16px);

  &__pic {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    margin: 0 20px;
    object-fit: cover;
  }
  &__loader {
    width: 300px;
    height: 13px;
    border-radius: 6px;
  }
}
