@import "../../styles/colors";
@import "../../styles/functions";

.error {
  &__container {
    background-color: $white;
    min-height: 80vh;
    flex-direction: column;
  }

  &__code {
    font-size: rem(80px);
    line-height: rem(120px);
  }
  &__title {
    font-size: rem(20px);
  }
  &__message {
    font-size: rem(19px);
    white-space: pre-wrap;
    margin: rem(16px) i;
  }
  &__button.primary.button {
    font-size: rem(18px);
    line-height: rem(20px);
    letter-spacing: rem(1.2px);
    border-radius: rem(5px);
    color: $white;
    margin: rem(24px) 0 0;
    &.redirecttohome {
      margin: 1rem 5rem;
      width: 16rem;
    }
  }
}
